/**
 * @generated SignedSource<<26a57fa7dcf99c21c2593a9d650a78b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentCard_ContentUsageFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ContentFooterSection_ContentUsageFragment">;
  readonly " $fragmentType": "ContentCard_ContentUsageFragment";
};
export type ContentCard_ContentUsageFragment$key = {
  readonly " $data"?: ContentCard_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentCard_ContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentCard_ContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentFooterSection_ContentUsageFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "c54e81776bc617766d1c2a451d96f922";

export default node;
