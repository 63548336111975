/**
 * @generated SignedSource<<d47ab9541bfd275e0b2508dabeaaa448>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentFooterSection_ContentUsageFragment$data = {
  readonly contentId: string;
  readonly hasViewerBookmarked: boolean;
  readonly id: string;
  readonly showComments: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"BookmarkButtonContentUsageFragment" | "ContentCommentButton_ContentUsageFragment" | "ContentReactionButton_ContentUsageFragment">;
  readonly " $fragmentType": "ContentFooterSection_ContentUsageFragment";
};
export type ContentFooterSection_ContentUsageFragment$key = {
  readonly " $data"?: ContentFooterSection_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentFooterSection_ContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentFooterSection_ContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerBookmarked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showComments",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentReactionButton_ContentUsageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentCommentButton_ContentUsageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookmarkButtonContentUsageFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "9ec76d1537cca1d7a5d24958e19d7bfa";

export default node;
