/**
 * @generated SignedSource<<e9e63ac54a9690a9afd7fcf0ad58afd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type contentUtils_getLocation$data = {
  readonly comment: {
    readonly contentUsageId: string | null;
    readonly id: string;
    readonly parentContent: {
      readonly " $fragmentSpreads": FragmentRefs<"contentUtils_getLocation_parentContent">;
    };
    readonly product: {
      readonly slug: string;
    } | null;
  } | null;
  readonly id: string;
  readonly type: ContentType;
  readonly " $fragmentSpreads": FragmentRefs<"contentUtils_getLocation_parentContent">;
  readonly " $fragmentType": "contentUtils_getLocation";
};
export type contentUtils_getLocation$key = {
  readonly " $data"?: contentUtils_getLocation$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentUtils_getLocation">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "contentUtils_getLocation"
};

(node as any).hash = "8a1d1419e2ea62d60437034f7c9a2fb7";

export default node;
