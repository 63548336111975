import { useActiveProduct } from "@/core/context/ActiveProductContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoLink, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import pluralize from "pluralize"
import { generatePath } from "react-router-dom"

interface CurriculumOutlineSummaryProps {
  curriculumModules: ReadonlyArray<{
    readonly title: string
    readonly content: ReadonlyArray<{
      readonly type: string
    }>
  }>
  loading?: boolean
}

function CurriculumOutlineSummary({
  curriculumModules,
  loading,
}: CurriculumOutlineSummaryProps) {
  const classes = useStyles()
  const activeProduct = useActiveProduct()
  const theme = useTheme()

  const moduleCount = curriculumModules.length
  const stats = curriculumModules.reduce(
    (acc, module) => {
      module.content.forEach((item) => {
        switch (item.type) {
          case "text":
            acc.lessons++
            break
          case "quiz":
            acc.quizzes++
            break
          case "assignment":
            acc.assignments++
            break
        }
      })
      return acc
    },
    { lessons: 0, quizzes: 0, assignments: 0 }
  )

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <DiscoIcon icon={"book"} color={"black"} width={16} height={16} />
        <DiscoText variant={"body-sm-600"}>{"Curriculum Preview"}</DiscoText>
      </div>
      {loading ? (
        <Skeleton
          variant={"rect"}
          width={400}
          style={{
            height: "16px",
            marginTop: "8px",
            borderRadius: theme.measure.borderRadius.small,
          }}
        />
      ) : (
        <div className={classes.statsContainer}>
          {activeProduct && (
            <>
              <DiscoText variant={"body-xs"} component={"div"}>
                {"In "}
                <DiscoLink
                  to={generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
                    productSlug: activeProduct?.slug,
                  })}
                  textVariant={"body-xs-500"}
                >
                  {activeProduct?.name}
                </DiscoLink>
              </DiscoText>
              <div className={classes.dot} />
            </>
          )}
          <div className={classes.stat}>
            <DiscoText variant={"body-xs"} className={classes.statLabel}>
              {`${moduleCount} ${pluralize("Module", moduleCount)}`}
            </DiscoText>
          </div>
          <div className={classes.dot} />
          <div className={classes.stat}>
            <DiscoText variant={"body-xs"} className={classes.statLabel}>
              {`${stats.lessons} ${pluralize("Lesson", stats.lessons)}`}
            </DiscoText>
          </div>
          <div className={classes.dot} />
          <div className={classes.stat}>
            <DiscoText variant={"body-xs"} className={classes.statLabel}>
              {`${stats.assignments} ${pluralize("Assignment", stats.assignments)}`}
            </DiscoText>
          </div>
          <div className={classes.dot} />
          <div className={classes.stat}>
            <DiscoText variant={"body-xs"} className={classes.statLabel}>
              {`${stats.quizzes} ${pluralize("Quiz", stats.quizzes)}`}
            </DiscoText>
          </div>
        </div>
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(1.5),
    gap: theme.spacing(0.5),
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.large,
    width: "100%",
    marginTop: theme.spacing(2),
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  statsContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    width: "100%",
  },
  stat: {
    display: "flex",
    alignItems: "center",
  },
  statLabel: {
    color: theme.palette.text.secondary,
  },
  dot: {
    width: 2,
    height: 2,
    borderRadius: "50%",
    backgroundColor: theme.palette.text.secondary,
  },
}))

export default CurriculumOutlineSummary
