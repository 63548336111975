import GenerateWithAIForm from "@/content/ai/GenerateWithAIForm"
import { AICanvasDrawerFormState } from "@/content/ai/drawer/AICanvasDrawerContent"
import { useSubmitAICanvasMessage } from "@/content/ai/hooks/useSubmitAICanvasMessage"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

type AICanvasDrawerMessageFormProps = TestIDProps & {
  aiGenerationId: GlobalID
  form: FormStore<AICanvasDrawerFormState>
  disableSubmit: boolean
}

function AICanvasDrawerMessageForm({
  aiGenerationId,
  form,
  testid = "AICanvasDrawerMessageForm",
  disableSubmit,
}: AICanvasDrawerMessageFormProps) {
  const { submitMessage } = useSubmitAICanvasMessage({ form, aiGenerationId })

  return (
    <GenerateWithAIForm
      form={form}
      onSubmit={handleSubmit}
      testid={testid}
      minimized
      disableSubmit={disableSubmit}
    />
  )

  async function handleSubmit() {
    await submitMessage(form.state.prompt!)
  }
}

export default observer(AICanvasDrawerMessageForm)
