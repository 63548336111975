import { useTrackAssetVideoProgressMutation } from "@/content/util/__generated__/useTrackAssetVideoProgressMutation.graphql"
import { sendSentryAnException } from "@/core/sentryHandler"
import Relay from "@/relay/relayUtils"
import { toJS } from "mobx"
import { useCallback, useRef } from "react"
import { graphql } from "relay-runtime"

export default function useTrackAssetVideoProgress() {
  const stopUpdatingVideoRef = useRef<boolean>(false)
  const trackAssetVideoProgress =
    Relay.useAsyncMutation<useTrackAssetVideoProgressMutation>(
      graphql`
        mutation useTrackAssetVideoProgressMutation(
          $input: TrackAssetVideoProgressInput!
        ) {
          response: trackAssetVideoProgress(input: $input) {
            errors {
              field
              message
            }
          }
        }
      `
    )

  return useCallback(
    async (vars: useTrackAssetVideoProgressMutation["variables"]) => {
      if (stopUpdatingVideoRef.current) {
        return
      }
      try {
        const response = await trackAssetVideoProgress(vars)

        // stop tracking video if video is deleted
        if (response.response.errors) {
          const videoNotFound = toJS(response.response.errors).some(
            (error) => error.message === "Video asset not found"
          )
          if (videoNotFound) stopUpdatingVideoRef.current = true
        }
      } catch (err) {
        sendSentryAnException(err, {
          extra: {
            title: "useTrackAssetVideoProgress",
          },
        })
      }
    },
    [trackAssetVideoProgress]
  )
}
