/**
 * @generated SignedSource<<303dbb199e66348d45fef2901e483c3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ContentSubscriptionStatus = "subscribed" | "unsubscribed" | "%future added value";
export type ManageContentSubscriptionInput = {
  contentId?: string | null;
  status?: ContentSubscriptionStatus | null;
  token?: string | null;
};
export type ContentSubscriptionDropdownItemMutation$variables = {
  input: ManageContentSubscriptionInput;
};
export type ContentSubscriptionDropdownItemMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly content: {
        readonly viewerIsSubscribed: boolean;
      };
    } | null;
  };
};
export type ContentSubscriptionDropdownItemMutation = {
  response: ContentSubscriptionDropdownItemMutation$data;
  variables: ContentSubscriptionDropdownItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsSubscribed",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentSubscriptionDropdownItemMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "ManageContentSubscriptionResponse",
        "kind": "LinkedField",
        "name": "manageContentSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentSubscription",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContentSubscriptionDropdownItemMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "ManageContentSubscriptionResponse",
        "kind": "LinkedField",
        "name": "manageContentSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentSubscription",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f6454902f942b8db7f4cdcb293732ff3",
    "id": null,
    "metadata": {},
    "name": "ContentSubscriptionDropdownItemMutation",
    "operationKind": "mutation",
    "text": "mutation ContentSubscriptionDropdownItemMutation(\n  $input: ManageContentSubscriptionInput!\n) {\n  response: manageContentSubscription(input: $input) {\n    node {\n      content {\n        viewerIsSubscribed\n        id\n      }\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f99e73aa6edcd2ca8f8762893bc5928b";

export default node;
