import { GenerateWithAIFormState } from "@/content/ai/button/GenerateWithAIButton"
import ResumeWithAIButton from "@/content/ai/button/ResumeWithAIButton"
import AICanvasDrawerHeader from "@/content/ai/drawer/AICanvasDrawerHeader"
import AICanvasDrawerMessageSidebar from "@/content/ai/drawer/AICanvasDrawerMessageSidebar"
import CurriculumOutlineContent from "@/content/ai/drawer/CurriculumOutlineContent"
import {
  AICanvasDrawerContentMutation,
  AIGenerationCurriculumModuleInput,
  ResumeAIGenerationInput,
} from "@/content/ai/drawer/__generated__/AICanvasDrawerContentMutation.graphql"
import { AICanvasDrawerContentPaginationQuery } from "@/content/ai/drawer/__generated__/AICanvasDrawerContentPaginationQuery.graphql"
import { AICanvasDrawerContentQuery } from "@/content/ai/drawer/__generated__/AICanvasDrawerContentQuery.graphql"
import { AICanvasDrawerContent_PaginationFragment$key } from "@/content/ai/drawer/__generated__/AICanvasDrawerContent_PaginationFragment.graphql"
import useTrackAIGenerationStatus from "@/content/ai/hooks/useTrackAIGenerationStatus"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { ObservableState, useFormStore } from "@/core/form/store/FormStore"
import RelayEnvironment from "@/relay/RelayEnvironment"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { useTheme } from "@material-ui/core"
import useEffectOnUpdate from "@utils/hook/useEffectOnUpdate"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import ConnectionHandlerPlus from "relay-connection-handler-plus"
import { commitLocalUpdate, graphql } from "relay-runtime"
import DeleteAIGenerationButton from "../buttons/DeleteAIGenerationButton"

export type AICanvasDrawerFormState = ResumeAIGenerationInput &
  Pick<
    GenerateWithAIFormState,
    | "assetEmbeddingSourceIds"
    | "contentEmbeddingSourceIds"
    | "trainingDataEmbeddingSourceIds"
    | "uploadedAssets"
  >

const AICanvasDrawerContent = observer(() => {
  const drawer = useGlobalDrawer("aiCanvas")
  const classes = useStyles()
  const activeProduct = useActiveProduct()!

  const { aiGenerationId, entityId } = drawer.params
  const theme = useTheme()

  const { node } = useLazyLoadQuery<AICanvasDrawerContentQuery>(
    graphql`
      query AICanvasDrawerContentQuery($id: ID!, $first: Int!, $after: String) {
        node(id: $id) {
          __typename
          ... on AIGeneration {
            id
            input {
              searchWeb
            }
            ...AICanvasDrawerContent_PaginationFragment
              @arguments(first: $first, after: $after)
            ...CurriculumOutlineContentFragment
          }
        }
      }
    `,
    {
      id: aiGenerationId,
      first: 100,
      after: null,
    },
    { fetchPolicy: "network-only" }
  )

  const aiGeneration = Relay.narrowNodeType(node, "AIGeneration")

  const { data, loadNext } = usePaginationFragment<
    AICanvasDrawerContentPaginationQuery,
    AICanvasDrawerContent_PaginationFragment$key
  >(
    graphql`
      fragment AICanvasDrawerContent_PaginationFragment on AIGeneration
      @refetchable(queryName: "AICanvasDrawerContentPaginationQuery")
      @argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
        id
        ...AICanvasDrawerMessageSidebar_messages @arguments(first: $first, after: $after)
        messages(first: $first, after: $after)
          @connection(key: "AICanvasDrawerContent_messages") {
          edges {
            node {
              id
              kind
              output {
                includeWelcomeModule
                curriculumModules {
                  title
                  content {
                    title
                    type
                    summary
                  }
                }
              }
              input {
                searchWeb
              }
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    aiGeneration
  )
  const messageList = Relay.connectionToArray(data?.messages)

  // Use the most recent searchWeb setting as the default
  const mostRecentInput = messageList.filter((msg) => msg.kind === "input").pop()
  const searchWeb = mostRecentInput?.input?.searchWeb ?? aiGeneration?.input.searchWeb

  const { status, isGenerationInProgress } = useTrackAIGenerationStatus({
    entityId,
  })

  useEffectOnUpdate(() => {
    if (status !== "draft") return
    commitLocalUpdate(RelayEnvironment, (store) => {
      if (!aiGeneration) return
      const aiGenerationRecord = store.get(aiGeneration.id)
      if (!aiGenerationRecord) return

      const messagesConnections = ConnectionHandlerPlus.getConnections(
        aiGenerationRecord,
        "AICanvasDrawerMessageSidebarFragment_messages"
      )
      if (!messagesConnections.length) return
      const connection = messagesConnections[0]
      const edges = connection.getLinkedRecords("edges") || []
      const filteredEdges = edges.filter((edge) => {
        const messageNode = edge?.getLinkedRecord("node")
        const id = messageNode?.getValue("id")
        return typeof id !== "string" || !id.startsWith("tmp_input_")
      })
      connection.setLinkedRecords(filteredEdges, "edges")
    })
    loadNext(20)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const form = useFormStore<AICanvasDrawerContentMutation, AICanvasDrawerFormState>(
    graphql`
      mutation AICanvasDrawerContentMutation($input: ResumeAIGenerationInput!) {
        response: resumeAIGeneration(input: $input) {
          node {
            status
            acceptedMessageId
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: aiGenerationId,
      prompt: "",
      referenceUrl: "",
      promptModuleIndex: null,
      promptContentIndex: null,
      referenceEmbeddingSourceIds: [],
      uploadedAssetIds: [],
      searchWeb,
      includeWelcomeModule: true,
      curriculumModules: [],
      acceptedMessageId: undefined,
      // Temporary state that won't be submitted to the mutation
      assetEmbeddingSourceIds: [],
      contentEmbeddingSourceIds: [],
      trainingDataEmbeddingSourceIds: [],
      uploadedAssets: [],
    }
  )

  // Select latest output message after loading completes
  const mostRecentOutput = messageList.filter((msg) => msg.kind === "output").pop()
  useEffect(() => {
    if (status !== "draft" || !mostRecentOutput?.id) return
    form.state.acceptedMessageId = mostRecentOutput.id
    form.state.includeWelcomeModule = mostRecentOutput.output?.includeWelcomeModule
    form.state.curriculumModules?.replace(
      mostRecentOutput.output
        ?.curriculumModules as unknown as ObservableState<AIGenerationCurriculumModuleInput>[]
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mostRecentOutput?.id])

  // Clear form state when regeneration starts
  useEffect(() => {
    if (!isGenerationInProgress) return
    form.state.acceptedMessageId = undefined
    form.state.curriculumModules = observable.array([])
  }, [isGenerationInProgress, form.state])

  if (!aiGeneration || !data) return null

  const CurriculumName = `${activeProduct?.name} Curriculum`

  const headerActions = (
    <>
      <ResumeWithAIButton
        aiGenerationId={aiGenerationId}
        onSuccess={drawer.close}
        testid={"curriculum-outline-review.approve-button"}
        form={form}
        disabled={isGenerationInProgress}
      >
        {"Generate Curriculum"}
      </ResumeWithAIButton>
      <DiscoMoreActionsDropdown
        moreActionsButtonClassName={classes.moreActionsDropdown}
        rotateIcon
        keepMounted
        testid={"curriculum-outline-review.more-actions-dropdown"}
      >
        <DeleteAIGenerationButton
          aiGenerationId={aiGenerationId}
          testid={"curriculum-outline-review.delete-button"}
          curriculumId={entityId}
          onDelete={() => {
            drawer.close()
          }}
        >
          {(buttonProps) => (
            <DiscoDropdownItem
              {...buttonProps}
              title={"Discard Outline"}
              testid={"curriculum-outline-review.delete-button"}
              icon={"trash"}
              color={theme.palette.groovy.red[500]}
            />
          )}
        </DeleteAIGenerationButton>
      </DiscoMoreActionsDropdown>
    </>
  )

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <AICanvasDrawerMessageSidebar
          aiGenerationKey={data}
          form={form}
          status={status}
        />
      </div>
      <div className={classes.mainContent}>
        <AICanvasDrawerHeader
          title={CurriculumName}
          onClose={drawer.close}
          actions={headerActions}
        />
        <div className={classes.mainContentWrapper}>
          <ScrollShadowContainer>
            <div className={classes.contentContainer}>
              <CurriculumOutlineContent form={form} aiGenerationKey={aiGeneration} />
            </div>
          </ScrollShadowContainer>
        </div>
      </div>
    </div>
  )
})

const useStyles = makeUseStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    overflow: "hidden",
  },
  sidebar: {
    width: "30%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mainContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    overflow: "hidden",
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.measure.borderRadius.large,
    boxShadow: theme.palette.groovyDepths.boxShadow,
  },
  mainContentWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    position: "relative",
  },
  contentContainer: {
    width: "100%",
    maxWidth: "750px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100%",
  },
  moreActionsDropdown: {
    height: "40px",
    width: "40px",
  },
}))

export default AICanvasDrawerContent
