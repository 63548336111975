/**
 * @generated SignedSource<<e737478514e832117d395258cbfd5901>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ControlAppContextQuery$variables = Record<PropertyKey, never>;
export type ControlAppContextQuery$data = {
  readonly viewer: {
    readonly avatar: string;
    readonly email: string;
    readonly firstName: string | null;
    readonly fullName: string;
    readonly id: string;
    readonly isControlSuperuser: boolean;
    readonly isStaff: boolean;
    readonly isVerified: boolean;
    readonly lastName: string | null;
    readonly timezone: string;
  } | null;
};
export type ControlAppContextQuery = {
  response: ControlAppContextQuery$data;
  variables: ControlAppContextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timezone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isVerified",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isStaff",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isControlSuperuser",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ControlAppContextQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ControlAppContextQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b1f03ba81397f12d1069f11bc88114e8",
    "id": null,
    "metadata": {},
    "name": "ControlAppContextQuery",
    "operationKind": "query",
    "text": "query ControlAppContextQuery {\n  viewer {\n    id\n    timezone\n    avatar\n    email\n    firstName\n    lastName\n    fullName\n    isVerified\n    isStaff\n    isControlSuperuser\n  }\n}\n"
  }
};
})();

(node as any).hash = "91449a2ded10627cc30d18f310439ad2";

export default node;
