/**
 * @generated SignedSource<<8e96e924de16cd85c3a75bbe5dd9692d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PinnedPostCardFragment$data = {
  readonly content: {
    readonly id: string;
    readonly name: string | null;
  };
  readonly createdBy: {
    readonly fullName: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  } | null;
  readonly creationDatetime: string;
  readonly id: string;
  readonly releaseDatetime: string | null;
  readonly releasedAt: string | null;
  readonly " $fragmentType": "PinnedPostCardFragment";
};
export type PinnedPostCardFragment$key = {
  readonly " $data"?: PinnedPostCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PinnedPostCardFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PinnedPostCardFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releasedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releaseDatetime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDatetime",
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();

(node as any).hash = "323b82d666941961d8c7878e8a7f694d";

export default node;
