/**
 * @generated SignedSource<<55db992875d9406bc850a448c17bbdaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type contentUtils_getLocation_parentContent$data = {
  readonly assignmentSubmission: {
    readonly contentUsageId: string;
    readonly id: string;
  } | null;
  readonly post: {
    readonly feedId: string;
    readonly id: string;
  } | null;
  readonly product: {
    readonly slug: string;
  } | null;
  readonly type: ContentType;
  readonly " $fragmentType": "contentUtils_getLocation_parentContent";
};
export type contentUtils_getLocation_parentContent$key = {
  readonly " $data"?: contentUtils_getLocation_parentContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentUtils_getLocation_parentContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "contentUtils_getLocation_parentContent"
};

(node as any).hash = "f42d1f0c90ee07d189d52987cbbb2e4b";

export default node;
