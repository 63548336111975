import BookmarkButton from "@/bookmarks/BookmarkButton"
import { BookmarkKind } from "@/bookmarks/__generated__/BookmarkButtonCreateMutation.graphql"
import { ContentFooterSection_ContentFragment$key } from "@/content/detail/sections/__generated__/ContentFooterSection_ContentFragment.graphql"
import { ContentFooterSection_ContentUsageFragment$key } from "@/content/detail/sections/__generated__/ContentFooterSection_ContentUsageFragment.graphql"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ContentCommentButton from "@/product/common/content/button/ContentCommentButton"
import ContentReactionButton from "@/product/common/content/button/ContentReactionButton"
import Relay from "@/relay/relayUtils"
import { RequireAtLeastOne } from "@/types/util/RequireAtLeastOne"
import ContentUniqueReactionsList from "@components/reactions/ContentUniqueReactionsList"
import { Skeleton } from "@material-ui/lab"
import classnames from "classnames"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type ContentFooterSectionProps = RequireAtLeastOne<{
  contentKey: ContentFooterSection_ContentFragment$key
  contentUsageKey: ContentFooterSection_ContentUsageFragment$key
}> & {
  testid: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onCommentButtonClick?: VoidFunction
  className?: string
  hideText?: boolean
  hideLikeButton?: boolean
  hideCommentButton?: boolean
  hideReactionsList?: boolean
  hideBookmarkText?: boolean
  bookmarkKind?: BookmarkKind
  readOnlyReactionButton?: boolean
  footerRightContent?: React.ReactNode
  customHoverClasses?: string
}

function ContentFooterSection({
  contentKey,
  contentUsageKey,
  testid,
  onClick,
  onCommentButtonClick,
  className,
  hideText,
  hideCommentButton,
  hideBookmarkText = false,
  hideReactionsList = false,
  bookmarkKind,
  hideLikeButton,
  readOnlyReactionButton,
  footerRightContent,
  customHoverClasses,
}: ContentFooterSectionProps) {
  const content = useFragment<ContentFooterSection_ContentFragment$key>(
    graphql`
      fragment ContentFooterSection_ContentFragment on Content {
        id
        hasViewerBookmarked
        showComments
        ...ContentReactionButton_ContentFragment
        ...ContentCommentButton_ContentFragment
        ...BookmarkButtonContentFragment
      }
    `,
    contentKey || null
  )

  const contentUsage = useFragment<ContentFooterSection_ContentUsageFragment$key>(
    graphql`
      fragment ContentFooterSection_ContentUsageFragment on ContentUsage {
        id
        contentId
        hasViewerBookmarked
        showComments
        ...ContentReactionButton_ContentUsageFragment
        ...ContentCommentButton_ContentUsageFragment
        ...BookmarkButtonContentUsageFragment
      }
    `,
    contentUsageKey || null
  )

  const classes = useStyles()

  const contentId = content?.id || contentUsage?.contentId || ""
  if (hideLikeButton && hideCommentButton) return null
  if (!content && !contentUsage) return null

  const contentHideComments =
    contentUsage?.showComments === false || (content && !content.showComments)

  const showContentReactionList =
    !hideLikeButton && !hideReactionsList && !readOnlyReactionButton

  return (
    <div
      className={classnames(classes.container, className)}
      data-testid={`${testid}.FooterSection`}
    >
      {!contentUsage && showContentReactionList && (
        <ContentUniqueReactionsList contentId={contentId} />
      )}
      <div className={classes.buttonGroup}>
        <div className={classes.leftButtonGroup}>
          {!hideLikeButton && (
            <ContentReactionButton
              contentId={contentId}
              contentUsageId={contentUsage?.id}
              hideText={hideText}
              readOnlyReactionButton={readOnlyReactionButton}
              testid={"ContentFooter"}
              size={"small"}
              {...getButtonProps()}
            />
          )}
          {!hideCommentButton && !contentHideComments && (
            <ContentCommentButton
              className={classes.statContainer}
              onClick={onCommentButtonClick ?? onClick}
              hideText={hideText}
              {...getButtonProps()}
            />
          )}
          {footerRightContent}
        </div>

        {bookmarkKind && content && (
          <BookmarkButton
            testid={`${testid}.ContentFooterSection`}
            contentKey={content}
            contentUsageKey={contentUsage || undefined}
            kind={bookmarkKind}
            showText={!hideText && !hideBookmarkText}
            className={
              !content.hasViewerBookmarked && !contentUsage?.hasViewerBookmarked
                ? customHoverClasses
                : ""
            }
          />
        )}

        {contentUsage && showContentReactionList && (
          <ContentUniqueReactionsList
            contentId={contentId}
            contentUsageId={contentUsage.id}
          />
        )}
      </div>
    </div>
  )

  function getButtonProps() {
    if (contentUsage) return { contentUsageKey: contentUsage }
    return { contentKey: content! }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  leftButtonGroup: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(0.5),

    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1),
    },
  },
  statContainer: {
    display: "flex",
    alignItems: "center",
  },
}))

interface SkeletonProps {
  className?: string
}

export function ContentFooterSectionSkeleton({ className }: SkeletonProps) {
  const classes = useStyles()
  return (
    <div className={classnames(classes.container, className)} style={{ width: "100%" }}>
      <Skeleton width={"50%"} className={classes.statContainer} />
      <Skeleton width={"50%"} className={classes.statContainer} />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ContentFooterSection,
  skeleton: ContentFooterSectionSkeleton,
})
