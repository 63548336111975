/**
 * @generated SignedSource<<38dccba3e7e2ca18991e5d0d9d4d7cd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InlineContentHeroSectionFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentHeroSectionFormFragment" | "InlineContentHeroSectionStaticFragment">;
  readonly " $fragmentType": "InlineContentHeroSectionFragment";
};
export type InlineContentHeroSectionFragment$key = {
  readonly " $data"?: InlineContentHeroSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentHeroSectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InlineContentHeroSectionFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InlineContentHeroSectionStaticFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InlineContentHeroSectionFormFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "4005015748346eb314df7f37078ef2fb";

export default node;
