import { AICanvasDrawerFormState } from "@/content/ai/drawer/AICanvasDrawerContent"
import {
  AIGenerationCurriculumModuleInput,
  AIGenerationStatus,
} from "@/content/ai/drawer/__generated__/AICanvasDrawerContentMutation.graphql"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import FormStore, { ObservableState } from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoAlert, DiscoIcon, DiscoText } from "@disco-ui"
import DiscoLoaderDots from "@disco-ui/loader/DiscoLoaderDots"
import { Skeleton } from "@material-ui/lab"
import { action } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useRef } from "react"
import { graphql, useRefetchableFragment } from "react-relay"
import AICanvasDrawerMessage from "./AICanvasDrawerMessage"
import AICanvasDrawerMessageForm from "./AICanvasDrawerMessageForm"
import AIGenerationReferenceList from "./AIGenerationReferenceList"
import { AICanvasDrawerMessageSidebarRefetchQuery } from "./__generated__/AICanvasDrawerMessageSidebarRefetchQuery.graphql"
import { AICanvasDrawerMessageSidebar_messages$key } from "./__generated__/AICanvasDrawerMessageSidebar_messages.graphql"

type AICanvasDrawerMessageSidebarProps = {
  aiGenerationKey: AICanvasDrawerMessageSidebar_messages$key
  form: FormStore<AICanvasDrawerFormState>
  status?: AIGenerationStatus
}

function AICanvasDrawerMessageSidebar({
  aiGenerationKey,
  form,
  status = "pending",
}: AICanvasDrawerMessageSidebarProps) {
  const classes = useStyles()
  const messageListRef = useRef<HTMLDivElement>(null)
  const activeProduct = useActiveProduct()!

  const [data] = useRefetchableFragment<
    AICanvasDrawerMessageSidebarRefetchQuery,
    AICanvasDrawerMessageSidebar_messages$key
  >(
    graphql`
      fragment AICanvasDrawerMessageSidebar_messages on AIGeneration
      @refetchable(queryName: "AICanvasDrawerMessageSidebarRefetchQuery")
      @argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
        ...AIGenerationReferenceListFragment
        input {
          searchWeb
          prompt
        }
        id
        messages(first: $first, after: $after)
          @connection(key: "AICanvasDrawerMessageSidebarFragment_messages") {
          totalCount
          edges {
            node {
              id
              kind
              output {
                includeWelcomeModule
                curriculumModules {
                  title
                  content {
                    title
                    type
                    summary
                  }
                }
              }
              ...AICanvasDrawerMessageFragment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    aiGenerationKey
  )

  const messageList = Relay.connectionToArray(data?.messages)
  const initialPrompt = data?.input?.prompt

  const scrollToBottom = () => {
    if (messageListRef.current) {
      requestAnimationFrame(() => {
        if (messageListRef.current) {
          messageListRef.current.scrollTo({
            top: messageListRef.current.scrollHeight,
            behavior: "smooth",
          })
        }
      })
    }
  }

  // Scroll when new messages arrive
  useEffect(() => {
    scrollToBottom()
  }, [messageList.length])

  const isLoadingGeneration = status === "pending" || status === "running"
  useEffect(() => {
    if (isLoadingGeneration) {
      setTimeout(scrollToBottom, 100)
    }
  }, [isLoadingGeneration])

  useEffect(() => {
    setTimeout(scrollToBottom, 100)
    setTimeout(scrollToBottom, 2000)
  }, [])

  const handleMessageSelect = action((id: string) => {
    form.state.acceptedMessageId = id
    const selectedMessage = messageList.find((msg) => msg.id === id)
    form.state.includeWelcomeModule = selectedMessage?.output?.includeWelcomeModule
    if (selectedMessage?.output?.curriculumModules) {
      form.state.curriculumModules?.replace(
        selectedMessage.output
          .curriculumModules as unknown as ObservableState<AIGenerationCurriculumModuleInput>[]
      )
    }
  })

  let draftVersion = 0

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <div className={classes.iconWrapper}>
            <DiscoIcon icon={"stars"} active />
          </div>
          <DiscoText variant={"heading-xs-700"}>{"AI Canvas"}</DiscoText>
        </div>
      </div>
      <div ref={messageListRef} className={classes.messageList}>
        {/* Initial Prompt Message */}
        <div className={classes.messageGroup}>
          <div className={classes.messageBox}>
            <div className={classes.messageContent}>
              <DiscoText variant={"body-md"}>{initialPrompt}</DiscoText>
            </div>
          </div>
        </div>
        <AIGenerationReferenceList aiGenerationKey={data} />

        {messageList.map((message) => {
          if (message.kind === "output") {
            draftVersion++
          }

          const draftName =
            message.kind === "output"
              ? `${activeProduct.name} Curriculum V${draftVersion}`
              : undefined

          return (
            <AICanvasDrawerMessage
              key={message.id}
              messageKey={message}
              isSelected={form.state.acceptedMessageId === message.id}
              onSelect={handleMessageSelect}
              isFirstOutput={message.kind === "output" && draftVersion === 1}
              draftName={draftName}
            />
          )
        })}

        {isLoadingGeneration && (
          <div className={classes.loadingPreviewContainer}>
            <Skeleton
              variant={"rect"}
              width={"95%"}
              height={20}
              style={{ borderRadius: 4 }}
            />
            <Skeleton
              variant={"rect"}
              width={"95%"}
              height={20}
              style={{ borderRadius: 4 }}
            />
            <Skeleton
              variant={"rect"}
              width={"60%"}
              height={20}
              style={{ borderRadius: 4 }}
            />
            <div className={classes.loadingPreview}>
              <DiscoText variant={"body-md-700"}>{"Editing preview"}</DiscoText>
              <DiscoLoaderDots size={"small"} />
            </div>
          </div>
        )}
        {status === "failed" && (
          <DiscoAlert severity={"error"} message={"Generation failed."} />
        )}
      </div>
      <AICanvasDrawerMessageForm
        aiGenerationId={data.id}
        form={form}
        disableSubmit={isLoadingGeneration}
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  iconWrapper: {
    backgroundColor: theme.palette.groovy.neutral[200],
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.medium,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
  },
  messageList: {
    flex: 1,
    overflowY: "auto",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    scrollBehavior: "smooth",
  },
  messageGroup: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  messageBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.groovy.neutral[200],
    borderRadius: theme.measure.borderRadius.medium,
  },
  messageContent: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(1),
  },
  loadingPreviewContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  loadingPreview: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    border: theme.palette.constants.borderSmall,
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.background.paper,
  },
}))

export default observer(AICanvasDrawerMessageSidebar)
