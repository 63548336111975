import { ContentHeaderSectionFragment$key } from "@/content/detail/sections/__generated__/ContentHeaderSectionFragment.graphql"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import { ProfileAvatarStackSkeleton } from "@/user/common/avatar-stack/ProfileAvatarStack"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import useUserTimezone from "@/user/util/useUserTimezone"
import { DiscoLink, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { getTimeDifferenceAsText } from "@utils/time/timeUtils"
import classnames from "classnames"
import { utcToZonedTime } from "date-fns-tz"
import { useFragment } from "react-relay"
import { generatePath, useParams } from "react-router-dom"
import { graphql } from "relay-runtime"

interface Props {
  contentKey: ContentHeaderSectionFragment$key
  testid: string
  className?: string
  overflow: React.ReactNode
  classes?: {
    overflow?: string
  }
}

function ContentHeaderSection({
  contentKey,
  testid,
  className,
  overflow,
  classes: propsClasses,
}: Props) {
  const classes = useStyles()
  const userTimezone = useUserTimezone()
  const { feedId } = useParams<{ feedId?: string }>()

  const content = useFragment<ContentHeaderSectionFragment$key>(
    graphql`
      fragment ContentHeaderSectionFragment on Content {
        id
        type
        createdBy {
          id
          ...ProfileAvatarWithDetailsFragment
        }
        product {
          name
          slug
        }
        releaseDatetime
        creationDatetime
        post {
          feed {
            id
            name
          }
          releasedAt
        }
      }
    `,
    contentKey
  )

  if (!content.createdBy) return null

  return (
    <div
      className={classnames(classes.header, className)}
      data-testid={`${testid}.HeaderSection`}
    >
      <ProfileAvatarWithDetails
        userKey={content.createdBy}
        details={getTimestamp()}
        stopClickPropagation
      />

      <div className={classnames(classes.overflow, propsClasses?.overflow)}>
        {overflow}
      </div>
    </div>
  )

  function getTimestamp() {
    const timeToNow = getTimeDifferenceAsText(
      utcToZonedTime(
        new Date(
          content.post?.releasedAt || content.releaseDatetime || content.creationDatetime
        ),
        userTimezone
      )
    )

    // Feed dashboard
    if (feedId) {
      return timeToNow
    }

    // If this is a post and we are outside of feed dashboard, show the feed name and link to it
    // Otherwise, show the product name and link to it if content product exists
    if (content.post?.feed.name || content.product) {
      return (
        <div className={classes.headerSubtitleContainer}>
          <DiscoText
            truncateText={1}
            variant={"body-xs-500-uppercase"}
            className={classes.headerSubtitle}
            color={"text.secondary"}
            display={"inline"}
            align={"left"}
          >
            {`${timeToNow} in `}
            <DiscoLink
              // Required to allow clicking this link without opening the profile
              onClick={(e) => e.stopPropagation()}
              className={classes.linkToPath}
              to={getLinkToDestination()}
            >
              <DiscoText
                variant={"body-xs-500-uppercase"}
                className={classes.headerSubtitle}
                color={"primary.main"}
                component={"span"}
              >
                {content.post?.feed?.name || content.product?.name}
              </DiscoText>
            </DiscoLink>
          </DiscoText>
        </div>
      )
    }

    // Organization level
    return timeToNow
  }

  function getLinkToDestination() {
    if (content.post?.feed) {
      return content.product
        ? generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
            productSlug: content.product.slug,
            feedId: content.post.feed.id,
          })
        : generatePath(ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST, {
            feedId: content.post.feed.id,
          })
    }

    if (content.product) {
      return generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
        productSlug: content.product.slug,
      })
    }

    return ""
  }
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(4),
    },
  },
  headerSubtitleContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "grid",
    },
  },
  headerSubtitle: {
    fontWeight: 400,
    textTransform: "inherit",
  },
  overflow: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  linkToPath: {
    marginBottom: "1px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
}))

interface SkeletonProps {
  className?: string
}

export function ContentHeaderSectionSkeleton({ className }: SkeletonProps) {
  const classes = useStyles()
  return (
    <div className={classnames(classes.header, className)}>
      <ProfileAvatarStackSkeleton stackSize={1} />
      <DiscoTextSkeleton width={"80%"} />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ContentHeaderSection,
  skeleton: ContentHeaderSectionSkeleton,
})
