/**
 * @generated SignedSource<<5d4fa8850567dcb3013e6a6c6f4cdee1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type curriculumUtils__getContentProgress$data = {
  readonly curriculumContentCompletions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly contentUsage: {
          readonly id: string;
        };
        readonly id: string;
      };
    }>;
  };
  readonly id: string;
  readonly product: {
    readonly curriculum: {
      readonly modules: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly content: {
              readonly children: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly content: {
                      readonly label: string;
                      readonly name: string | null;
                    };
                    readonly id: string;
                    readonly isReleased: boolean;
                  };
                }>;
              } | null;
            };
            readonly id: string;
          };
        }>;
        readonly totalCount: number;
      };
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ModuleCurriculumProgressFragment">;
  readonly " $fragmentType": "curriculumUtils__getContentProgress";
};
export type curriculumUtils__getContentProgress$key = {
  readonly " $data"?: curriculumUtils__getContentProgress$data;
  readonly " $fragmentSpreads": FragmentRefs<"curriculumUtils__getContentProgress">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "curriculumUtils__getContentProgress"
};

(node as any).hash = "64aa13469f02273de4d65cef293cd974";

export default node;
