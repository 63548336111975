/**
 * @generated SignedSource<<de08d24f55b130df79e4f63d8eaf238c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentThumbnail_ContentFragment$data = {
  readonly coverVideo: string | null;
  readonly id: string;
  readonly showCoverPlayIcon: boolean;
  readonly thumbnailUrl: string | null;
  readonly type: ContentType;
  readonly " $fragmentType": "ContentThumbnail_ContentFragment";
};
export type ContentThumbnail_ContentFragment$key = {
  readonly " $data"?: ContentThumbnail_ContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentThumbnail_ContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentThumbnail_ContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverVideo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showCoverPlayIcon",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "b1023187f86956adcb221b67f47b898e";

export default node;
