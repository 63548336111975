import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIButton from "@components/ai/AIButton"
import AIInput from "@components/ai/AIInput"
import { DiscoIcon } from "@disco-ui"
import { useDropdownContext } from "@disco-ui/dropdown/DiscoDropdown"
import { useState } from "react"

interface CurriculumFloatingInputProps {
  name: string
  onSubmit: (prompt: string) => void
}

const CurriculumFloatingInput = ({ name, onSubmit }: CurriculumFloatingInputProps) => {
  const [prompt, setPrompt] = useState("")
  const classes = useStyles()
  const testid = `CurriculumFloatingInput`
  const { closeParent } = useDropdownContext()

  const handleSubmit = () => {
    if (!prompt.trim()) return

    onSubmit(prompt)
    setPrompt("")
    // Use a synthetic mouse event to close input dropdown
    const syntheticEvent = new MouseEvent(
      "click"
    ) as unknown as React.MouseEvent<HTMLElement>
    closeParent(syntheticEvent)
  }

  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        <AIInput
          showBadge={false}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          onEnterPress={handleSubmit}
          placeholder={`Enter instructions to edit ${name}...`}
          rows={1}
          fullWidth
        />
        <div className={classes.submitButton}>
          <AIButton
            onClick={handleSubmit}
            testid={`${testid}.submit-button`}
            noIcon={true}
            className={classes.button}
            disabled={!prompt.trim()}
          >
            <DiscoIcon
              color={"white"}
              active
              icon={"iconsax.arrow-up"}
              width={16}
              height={16}
            />
          </AIButton>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  inputContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  submitButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: "50%",
    transform: "translateY(-50%)",
  },
  button: {
    minWidth: "32px",
    width: "32px",
    height: "32px",
    padding: 0,
  },
}))

export default CurriculumFloatingInput
