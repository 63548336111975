import { GenerationStatus } from "@/content/ai/GenerateContentWithAIProvider"
import { AIGenerationStatus } from "@/content/ai/button/__generated__/GenerateWithAIButtonMutation.graphql"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import AICloseIcon from "@assets/disco/icons/ai/ai-close.svg"
import AIStarsIcon from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIButton from "@components/ai/AIButton"
import AICheck from "@components/ai/AICheck"
import AIText from "@components/ai/AIText"
import { DiscoAlert, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import classNames from "classnames"

type PopupStatus = GenerationStatus | AIGenerationStatus

export type AIPopupStatusItem = {
  name?: string
  status: PopupStatus
  loadingText?: string
}

type Props = {
  statuses: AIPopupStatusItem[]
  referenceStatuses?: Omit<AIPopupStatusItem, "loadingText">[]
  title?: string
  onClose: () => void
  hideCloseButton?: boolean
  footerMessage?: string
  aiGenerationId?: string
  entityId?: string
  infoMessage?: string
  showReviewButton?: boolean
  classes?: ClassNameMap<"container">
}

function GenerateContentWithAIStatusPopup(props: Props) {
  const classes = useStyles()
  const aiCanvasDrawer = useGlobalDrawer("aiCanvas")
  const {
    statuses,
    referenceStatuses = [],
    title,
    onClose,
    hideCloseButton = false,
    footerMessage = "It may take a few minutes to generate the content.",
    aiGenerationId,
    entityId,
    infoMessage,
    showReviewButton = false,
    classes: customClasses,
  } = props

  return (
    <div className={classNames(classes.container, customClasses?.container)}>
      <div className={classes.header}>
        <AIStarsIcon width={24} height={24} />
        <AIText variant={"body-md-600"}>{title || "Generating Content"}</AIText>
        {!hideCloseButton && (
          <DiscoContainerButton onClick={onClose} className={classes.closeButton}>
            <AICloseIcon />
          </DiscoContainerButton>
        )}
      </div>

      {infoMessage && (
        <DiscoText variant={"body-md"} className={classes.infoMessage}>
          {infoMessage}
        </DiscoText>
      )}
      {statuses.map(({ name, status, loadingText }) => (
        <div key={name} className={classes.mainStatus}>
          {getGeneratingCheckbox(status)}
          <div className={classes.textContainer}>
            {name && (
              <DiscoText
                variant={"body-sm-600"}
                component={"span"}
                truncateText={1}
                className={classes.nameText}
              >
                {`${name}: `}
              </DiscoText>
            )}
            <DiscoText
              component={"span"}
              truncateText={1}
              variant={"body-sm"}
              className={classes.generatingText}
            >
              {getGeneratingText(status, loadingText)}
            </DiscoText>
          </div>
        </div>
      ))}

      {/* Reference Statuses */}
      {referenceStatuses.length > 0 && (
        <div className={classes.referencesContainer}>
          <DiscoText variant={"body-sm-600"} color={"text.secondary"} marginBottom={0.5}>
            {"References"}
          </DiscoText>
          {referenceStatuses.map(
            ({ name, status }) =>
              status && (
                <div key={name} className={classes.referenceItem}>
                  {getGeneratingCheckbox(status, true)}
                  <div className={classes.textContainer}>
                    {name && (
                      <DiscoText
                        variant={"body-sm"}
                        component={"span"}
                        truncateText={1}
                        className={classes.nameText}
                      >
                        {name}
                      </DiscoText>
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      )}

      {!infoMessage && footerMessage && (
        <div className={classes.footer}>
          <DiscoAlert
            severity={"default"}
            message={footerMessage}
            classes={{ root: classes.alert }}
          />
        </div>
      )}

      {showReviewButton && (
        <AIButton
          onClick={() => {
            aiCanvasDrawer.setParams({ aiGenerationId, entityId })
          }}
          className={classes.reviewButton}
        >
          {"Edit Curriculum Preview"}
        </AIButton>
      )}
    </div>
  )

  function getGeneratingText(generationStatus: PopupStatus, loadingText?: string) {
    if (generationStatus === "done" || generationStatus === "completed") {
      return `${loadingText?.replace("Processing", "Processed") || "Done"}`
    }
    if (generationStatus === "failed") {
      return `${
        loadingText?.replace("Processing", "Failed processing") ||
        "Something went wrong, please try again."
      }`
    }
    return loadingText || "Loading..."
  }
  function getGeneratingCheckbox(generationStatus: PopupStatus, isReference?: boolean) {
    if (generationStatus === "done" || generationStatus === "completed") {
      return <AICheck isChecked size={isReference ? "small" : "medium"} />
    }
    if (generationStatus === "failed") {
      return <AICheck hasError size={isReference ? "small" : "medium"} />
    }
    return <AICheck size={isReference ? "small" : "medium"} />
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: theme.zIndex.raise2,
    width: "100%",
    maxWidth: "400px",
    background: `${
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurpleDark
        : theme.palette.aiGradient.aiLight
    } padding-box, ${theme.palette.aiGradient.bluePurple02} border-box`,
    border: "1px solid transparent",
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    borderRadius: theme.measure.borderRadius.large,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  closeButton: {
    marginLeft: "auto",
    height: 24,
    width: 24,
  },
  mainStatus: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    minWidth: 0,
    padding: theme.spacing(0.5, 0),
  },
  referencesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  referenceItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    minWidth: 0,
    "& > p": {
      flex: 1,
      minWidth: 0,
      overflow: "hidden",
    },
  },
  footer: {
    marginTop: theme.spacing(1),
  },
  alert: {
    background: "transparent",
    padding: 0,
    color: theme.palette.text.secondary,
  },
  reviewButton: {
    marginLeft: "auto",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minWidth: 0,
  },
  nameText: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: theme.spacing(0.5),
  },
  generatingText: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  infoMessage: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
}))

export default GenerateContentWithAIStatusPopup
