/**
 * @generated SignedSource<<4f39f79428094372c90ee8e50d04528c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "add_events_to_calendar" | "calendar_connection" | "community_agreement" | "complete_profile" | "connect_slack" | "custom_profile_field" | "default_profile_fields" | "download_mobile_app" | "onboarding_complete" | "onboarding_module" | "onboarding_welcome" | "slack_connection" | "slack_invite" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type curriculumUtils__getNextCurriculumItem$data = {
  readonly id: string;
  readonly modules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly content: {
          readonly children: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly content: {
                  readonly id: string;
                  readonly systemTaskKind: ContentSystemTaskKind | null;
                };
                readonly id: string;
                readonly isLocked: boolean;
              };
            }>;
          } | null;
          readonly id: string;
        };
        readonly id: string;
      };
    }>;
  };
  readonly " $fragmentType": "curriculumUtils__getNextCurriculumItem";
};
export type curriculumUtils__getNextCurriculumItem$key = {
  readonly " $data"?: curriculumUtils__getNextCurriculumItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"curriculumUtils__getNextCurriculumItem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "curriculumUtils__getNextCurriculumItem"
};

(node as any).hash = "e9a8bf34a1adff6e581ceaa6926eb707";

export default node;
