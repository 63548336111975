/**
 * @generated SignedSource<<79f31ad970f61cbe052bd712cc517992>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InlineContentHeroAndProperties_ContentFragment$data = {
  readonly attachments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly mediaUrl: string | null;
        readonly name: string;
      };
    }>;
  };
  readonly coverPhoto: string | null;
  readonly coverVideo: string | null;
  readonly coverVideoDurationSeconds: number | null;
  readonly id: string;
  readonly name: string | null;
  readonly showComments: boolean;
  readonly showCoverPlayIcon: boolean;
  readonly thumbnailUrl: string | null;
  readonly type: ContentType;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentAttachmentsSectionFragment" | "InlineContentHeroButtonsFragment" | "InlineContentHeroSectionFragment">;
  readonly " $fragmentType": "InlineContentHeroAndProperties_ContentFragment";
};
export type InlineContentHeroAndProperties_ContentFragment$key = {
  readonly " $data"?: InlineContentHeroAndProperties_ContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentHeroAndProperties_ContentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InlineContentHeroAndProperties_ContentFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentAttachmentNodeConnection",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentAttachmentNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentAttachment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mediaUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverPhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverVideo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverVideoDurationSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showComments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showCoverPlayIcon",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InlineContentHeroSectionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InlineContentAttachmentsSectionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InlineContentHeroButtonsFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};
})();

(node as any).hash = "d7871959f071a5fd9033f9aecbba1909";

export default node;
