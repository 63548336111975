/**
 * @generated SignedSource<<55453ca1b4ab62b5cac1e9354a4365d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InlineContentHeroSectionFormFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentCoverVideoFormFragment">;
  readonly " $fragmentType": "InlineContentHeroSectionFormFragment";
};
export type InlineContentHeroSectionFormFragment$key = {
  readonly " $data"?: InlineContentHeroSectionFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentHeroSectionFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InlineContentHeroSectionFormFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InlineContentCoverVideoFormFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "2bcdbf9a83fc2016b9310aacc7c5f15b";

export default node;
