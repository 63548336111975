import { AICanvasDrawerFormState } from "@/content/ai/drawer/AICanvasDrawerContent"
import { useSubmitAICanvasMessage } from "@/content/ai/hooks/useSubmitAICanvasMessage"
import useTrackAIGenerationStatus from "@/content/ai/hooks/useTrackAIGenerationStatus"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDropdown, DiscoIcon, DiscoIconButton } from "@disco-ui"
import CurriculumFloatingInput from "./CurriculumFloatingInput"

interface CurriculumActionButtonsProps {
  type: "module" | "item"
  form: FormStore<AICanvasDrawerFormState>
  aiGenerationId: GlobalID
  moduleIndex: number
  itemIndex?: number
  name: string
  isWelcomeModule?: boolean
}

const CurriculumActionButtons = ({
  type,
  form,
  aiGenerationId,
  moduleIndex,
  itemIndex,
  name,
  isWelcomeModule = false,
}: CurriculumActionButtonsProps) => {
  const classes = useStyles()
  const activeProduct = useActiveProduct()
  const { submitMessage } = useSubmitAICanvasMessage({ form, aiGenerationId })
  const { isGenerationInProgress } = useTrackAIGenerationStatus({
    entityId: activeProduct?.curriculum?.id || "",
  })

  return (
    <div className={classes.actionButtons}>
      {!isWelcomeModule && (
        <DiscoDropdown
          disableCloseOnClick
          menuClasses={{ paper: classes.editDropdown }}
          menuButton={({ onClick }) => (
            <DiscoIconButton
              onClick={onClick}
              tooltip={`Edit ${type}`}
              width={16}
              height={16}
              disabled={isGenerationInProgress}
            >
              <DiscoIcon icon={"edit"} width={16} height={16} />
            </DiscoIconButton>
          )}
        >
          <CurriculumFloatingInput
            name={name}
            onSubmit={(prompt) => {
              handleEdit(prompt)
            }}
          />
        </DiscoDropdown>
      )}

      {(isWelcomeModule ||
        type === "item" ||
        (type === "module" && (form.state.curriculumModules?.length ?? 0) > 1)) && (
        <DiscoIconButton
          onClick={handleDelete}
          tooltip={`Remove ${isWelcomeModule ? "Welcome Module" : type}`}
          width={16}
          height={16}
          disabled={isGenerationInProgress}
        >
          <DiscoIcon icon={"trash"} />
        </DiscoIconButton>
      )}
    </div>
  )

  function handleEdit(prompt: string) {
    submitMessage(prompt, moduleIndex, type === "item" ? itemIndex : null)
  }

  function handleDelete() {
    if (isWelcomeModule) {
      form.state.includeWelcomeModule = false
      return
    }

    if (!form.state.curriculumModules) return
    if (type === "module") {
      form.state.curriculumModules.splice(moduleIndex, 1)
    } else if (type === "item" && itemIndex !== undefined) {
      form.state.curriculumModules[moduleIndex].content.splice(itemIndex, 1)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  actionButtons: {
    display: "flex",
    position: "absolute",
    gap: theme.spacing(1),
    right: -60,
    top: 5,
    transform: "translateY(-50%)",
    zIndex: 1,
  },
  editDropdown: {
    marginTop: theme.spacing(5),
    minWidth: "800px",
    boxShadow: theme.palette.groovyDepths.boxShadow,
  },
}))

export default CurriculumActionButtons
