import { ControlUserEmailActivityQuery } from "@/control/components/__generated__/ControlUserEmailActivityQuery.graphql"
import Relay from "@/relay/relayUtils"
import { DiscoStaticTable, DiscoTextSkeleton } from "@disco-ui"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

export interface ControlUserEmailActivityProps {
  userId: string
}

interface EmailActivityMessage {
  id: string
  subject: string
  fromEmail: string
  lastEventTime: string
  status: string
  opensCount: number
}

function ControlUserEmailActivity({ userId }: ControlUserEmailActivityProps) {
  const data = useLazyLoadQuery<ControlUserEmailActivityQuery>(
    graphql`
      query ControlUserEmailActivityQuery($id: ID!) {
        emailActivity: controlGetUserEmailActivity(id: $id) {
          messages {
            id
            subject
            fromEmail
            lastEventTime
            status
            opensCount
          }
        }
      }
    `,
    { id: userId },
    {
      fetchPolicy: "network-only",
    }
  )

  const messages = data?.emailActivity?.messages || []

  return (
    <>
      {data?.emailActivity && (
        <DiscoStaticTable
          rows={messages.map((message: EmailActivityMessage) => (
            <tr key={message.id}>
              <td>{message.subject}</td>
              <td>{message.fromEmail}</td>
              <td>{new Date(message.lastEventTime).toLocaleString()}</td>
              <td>{message.status}</td>
              <td>{message.opensCount}</td>
            </tr>
          ))}
          header={[
            { value: "Subject" },
            { value: "From" },
            { value: "Date" },
            { value: "Status" },
            { value: "Opens" },
          ]}
        />
      )}
    </>
  )
}

export default Relay.withSkeleton({
  component: ControlUserEmailActivity,
  skeleton: () => <DiscoTextSkeleton variant={"heading-xl"} width={"100%"} />,
})
