import { AICanvasDrawerFormState } from "@/content/ai/drawer/AICanvasDrawerContent"
import { CurriculumOutlineContentFragment$key } from "@/content/ai/drawer/__generated__/CurriculumOutlineContentFragment.graphql"
import FormStore from "@/core/form/store/FormStore"
import ProcessingSourceIcon from "@/core/ui/disco/icons/ai/processing-source.svg"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoLoaderDots from "@disco-ui/loader/DiscoLoaderDots"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { range } from "@utils/array/arrayUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { graphql, useFragment } from "react-relay"
import CurriculumOutlineSummary from "./CurriculumOutlineSummary"
import CurriculumModule from "./components/CurriculumModule"

interface CurriculumOutlineContentProps {
  form: FormStore<AICanvasDrawerFormState>
  aiGenerationKey: CurriculumOutlineContentFragment$key
}

function CurriculumOutlineContent({
  form,
  aiGenerationKey,
}: CurriculumOutlineContentProps) {
  const classes = useStyles()
  const theme = useTheme()
  const curriculumModules = form.state.curriculumModules || []

  const aiGeneration = useFragment<CurriculumOutlineContentFragment$key>(
    graphql`
      fragment CurriculumOutlineContentFragment on AIGeneration {
        id
        status
        input {
          searchWeb
        }
        references {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    `,
    aiGenerationKey
  )

  // Override status to draft if an older output message is selected while generating
  const status = form.state.acceptedMessageId ? "draft" : aiGeneration.status
  const references = Relay.connectionToArray(aiGeneration.references)

  // Show a special error message if generation failed because there's no usable sources
  const allReferencesFailed =
    references.every((r) => r.status === "failed") && !aiGeneration.input.searchWeb

  // Loading screen when processing sources
  if (status === "pending")
    return (
      <div className={classNames(classes.outline, classes.outlineCentered)}>
        <div className={classes.centeredContainer}>
          <ProcessingSourceIcon width={87} height={62} />
          <DiscoText variant={"heading-sm"}>{"Processing sources..."}</DiscoText>
          <DiscoText>
            {
              "You can exit this window and come back later. We'll email you when your curriculum preview is ready."
            }
          </DiscoText>
        </div>
      </div>
    )

  // Error screen when generation failed
  if (status === "failed")
    return (
      <div className={classNames(classes.outline, classes.outlineCentered)}>
        <div className={classes.centeredContainer}>
          <div className={classes.errorIcon}>
            <DiscoIcon icon={"close"} color={theme.palette.text.danger} />
          </div>
          <DiscoText variant={"heading-sm"}>
            {allReferencesFailed
              ? `We couldn't process the attached ${pluralize(
                  "source",
                  references.length
                )}`
              : "Something went wrong"}
          </DiscoText>
          <DiscoText>
            {allReferencesFailed
              ? "Something went wrong while processing your reference material. This may be due to a failed upload, an unsupported format, or an issue extracting content from the source. Please check the file or link and try again."
              : "We encountered an issue while generating your curriculum preview. This could be due to a temporary system issue or an unexpected error. Please try a follow-up prompt or check your sources."}
          </DiscoText>
        </div>
      </div>
    )

  // Outline generation loading screen or draft display
  const loading = status === "running"
  const showWelcomeModule = !loading && form.state.includeWelcomeModule !== false

  return (
    <div className={classes.outline}>
      <CurriculumOutlineSummary curriculumModules={curriculumModules} loading={loading} />

      {loading ? (
        <>
          <CurriculumOutlineContentSkeleton />
          <div className={classes.loadingOverlay}>
            <DiscoLoaderDots />
          </div>
        </>
      ) : (
        <>
          {/* Welcome Module */}
          {showWelcomeModule && (
            <CurriculumModule
              key={"welcome-module"}
              title={"Welcome Module"}
              index={-1} // Special index for welcome module
              content={[]}
              form={form}
              aiGenerationId={aiGeneration.id}
              isWelcomeModule={true}
            />
          )}

          {/* Generated Modules */}
          {curriculumModules.map((module, index) => (
            <CurriculumModule
              key={module.title}
              title={module.title}
              index={index}
              content={module.content}
              form={form}
              aiGenerationId={aiGeneration.id}
              isWelcomeModule={false}
            />
          ))}
        </>
      )}
    </div>
  )
}

const CurriculumOutlineContentSkeleton = () => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.skeletonContainer}>
      <div className={classes.moduleContainer}>
        <DiscoTextSkeleton
          variant={"heading-lg"}
          width={"70%"}
          height={"55px"}
          style={{ marginBottom: theme.spacing(2) }}
        />
        <div className={classes.itemsContainer}>
          {range(3).map((itemIndex) => (
            <div key={itemIndex} className={classes.itemContainer}>
              <Skeleton
                variant={"rect"}
                width={"60%"}
                height={20}
                style={{ borderRadius: 4 }}
              />
              <Skeleton
                variant={"rect"}
                width={"100%"}
                height={40}
                style={{ borderRadius: 4 }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  outline: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1000px",
    gap: theme.spacing(1),
    position: "relative",
    width: "100%",
    flexGrow: 1,
  },
  outlineCentered: {
    alignItems: "center",
    justifyContent: "center",
  },
  skeletonContainer: {
    marginTop: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  moduleContainer: {
    marginBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3.5),
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  loadingOverlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(3),
    width: theme.spacing(20),
    height: theme.spacing(20),
    backgroundColor: `${theme.palette.background.paper}EE`,
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.boxShadow,
  },
  centeredContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
    maxWidth: "460px",
    textAlign: "center",
  },
  errorIcon: {
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    display: "flex",
    justifyItems: "center",
    alignContent: "center",
    backgroundColor: theme.palette.error.light,
  },
}))

export default observer(CurriculumOutlineContent)
