/**
 * @generated SignedSource<<1423f9a86ee3cc3076c89a15f7f0a6d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentFooterSection_ContentFragment$data = {
  readonly hasViewerBookmarked: boolean;
  readonly id: string;
  readonly showComments: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"BookmarkButtonContentFragment" | "ContentCommentButton_ContentFragment" | "ContentReactionButton_ContentFragment">;
  readonly " $fragmentType": "ContentFooterSection_ContentFragment";
};
export type ContentFooterSection_ContentFragment$key = {
  readonly " $data"?: ContentFooterSection_ContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentFooterSection_ContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentFooterSection_ContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerBookmarked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showComments",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentReactionButton_ContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentCommentButton_ContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookmarkButtonContentFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "16655cd68756806cfaaaea38fd2b6c2d";

export default node;
