import ControlUserRemoveButton from "@/control/components/ControlUserRemoveButton"
import { ControlUserDetailPage_updateUserMutation } from "@/control/page/__generated__/ControlUserDetailPage_updateUserMutation.graphql"
import { ControlUserDetailPageQuery } from "@/control/page/__generated__/ControlUserDetailPageQuery.graphql"
import CONTROL_ROUTE_NAMES from "@/control/route/util/controlRouteNames"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import BackButton from "@components/back-button/BackButton"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoLink, DiscoSection, DiscoSwitch, DiscoText } from "@disco-ui"
import DiscoPage from "@disco-ui/page/DiscoPage"
import { useTheme } from "@material-ui/core/styles"
import { useState } from "react"
import { generatePath, useParams } from "react-router-dom"
import { graphql } from "relay-runtime"
import ControlChangeUserEmailButton from "../components/ControlChangeUserEmailButton"
import ControlRemoveDiscoStaffMembershipButton from "../components/ControlRemoveDiscoStaffMembershipButton"
import ControlUserEmailActivity from "../components/ControlUserEmailActivity"
import { useControlAuthUser } from "../context/ControlAuthUserContext"

export interface ControlUserDetailPageProps {
  id: string
}

function ControlUserDetailPage() {
  const { controlAuthUser } = useControlAuthUser()
  const { id: userId } = useParams<ControlUserDetailPageProps>()
  const theme = useTheme()
  const classes = useStyles()

  const id = Relay.toGlobalId("User", userId)

  const [{ user }, refetch] = Relay.useRefetchableQuery<ControlUserDetailPageQuery>(
    graphql`
      query ControlUserDetailPageQuery($id: ID!) {
        user: controlGetUser(id: $id) {
          id
          fullName
          email
          canCreateMultipleTrials
          organizationMemberships {
            edges {
              node {
                id
                isDiscoStaff
                organization {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    { id },
    { fetchPolicy: "network-only" }
  )

  const organizationMemberships = Relay.connectionToArray(user?.organizationMemberships)

  const updateUser = Relay.useAsyncMutation<ControlUserDetailPage_updateUserMutation>(
    graphql`
      mutation ControlUserDetailPage_updateUserMutation($input: ControlUpdateUserInput!) {
        controlUpdateUser(input: $input) {
          node {
            id
            canCreateMultipleTrials
          }
          errors {
            field
            message
          }
        }
      }
    `
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [canCreateMultipleTrials, setCanCreateMultipleTrials] = useState(
    user.canCreateMultipleTrials
  )

  return (
    <>
      <DiscoPage
        headerContent={
          <DiscoSection padding={1.5}>
            <BackButton
              label={"Back"}
              defaultPath={CONTROL_ROUTE_NAMES.USERS.ROOT}
              marginBottom={5}
              color={theme.palette.text.primary}
            />
          </DiscoSection>
        }
      >
        <DiscoSection padding={1.5}>
          <DiscoText>{`ID: ${userId}`}</DiscoText>
          <DiscoText>{`Name: ${user.fullName}`}</DiscoText>
          <DiscoText>{`Email: ${user.email}`}</DiscoText>
          <DiscoText variant={"body-lg-500"} marginTop={2}>
            {"Communities"}
          </DiscoText>
          {organizationMemberships.map((om) => (
            <div key={om.id} className={classes.membership}>
              <DiscoLink
                key={om.id}
                style={{ display: "block" }}
                to={generatePath(CONTROL_ROUTE_NAMES.COMMUNITIES.DETAIL, {
                  id: Relay.fromGlobalId(om.organization.id).id,
                })}
              >
                {om.organization.name}
              </DiscoLink>
              {om.isDiscoStaff && (
                <ControlRemoveDiscoStaffMembershipButton
                  organizationMembershipId={om.id}
                  onSubmit={() => refetch({ id })}
                >
                  {"Remove Staff Membership"}
                </ControlRemoveDiscoStaffMembershipButton>
              )}
            </div>
          ))}
        </DiscoSection>
        <DiscoText variant={"body-lg-500"} marginTop={2}>
          {"Email Activity (last 10 emails)"}
        </DiscoText>
        <DiscoSection padding={1.5}>
          <ControlUserEmailActivity userId={user.id} />
        </DiscoSection>
        <DiscoSection padding={1.5}>
          <DiscoText variant={"body-lg-500"}>{"Settings"}</DiscoText>
          <div className={classes.setting}>
            <DiscoSwitch
              label={"Can Create Multiple Trial Communities"}
              checked={canCreateMultipleTrials}
              onChange={setCanCreateMultipleTrials}
            />
          </div>
          <DiscoButton
            variant={"outlined"}
            color={"grey"}
            disabled={isSubmitting}
            shouldDisplaySpinner={isSubmitting}
            onClick={saveSettings}
          >
            {"Save Settings"}
          </DiscoButton>
        </DiscoSection>
        {controlAuthUser?.isControlSuperuser && (
          <DiscoSection padding={1.5}>
            <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={3}>
              {"Change this user's email."}
            </DiscoText>
            <ControlChangeUserEmailButton
              userId={user.id}
              onSubmit={() => refetch({ id })}
            >
              {"Change Email"}
            </ControlChangeUserEmailButton>
          </DiscoSection>
        )}
        <DiscoSection padding={1.5}>
          <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={3}>
            {`Remove this user. NOTE: This is only allowed if they are not a member of any community.`}
          </DiscoText>
          <ControlUserRemoveButton
            userId={user.id}
            disabled={organizationMemberships.length > 0}
          >
            {"Remove User"}
          </ControlUserRemoveButton>
        </DiscoSection>
      </DiscoPage>
    </>
  )

  async function saveSettings() {
    setIsSubmitting(true)
    try {
      await updateUser({
        input: {
          userId: user.id,
          canCreateMultipleTrials,
        },
      })
      displaySuccessToast({ message: "Settings saved" })
    } finally {
      setIsSubmitting(false)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  membership: {
    display: "flex",
    padding: theme.spacing(1),
  },
  setting: {
    marginBottom: theme.spacing(1),
  },
}))

export default ControlUserDetailPage
