/**
 * @generated SignedSource<<b6715d1d7ee1007d545eaf8b89fc739c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AIGenerationStatus = "completed" | "draft" | "failed" | "pending" | "running" | "%future added value";
export type CreateAIGenerationInput = {
  entityId: string;
  includeWelcomeModule?: boolean | null;
  productId?: string | null;
  prompt: string;
  referenceEmbeddingSourceIds?: ReadonlyArray<string> | null;
  referenceUrl?: string | null;
  searchWeb?: boolean | null;
  uploadedAssetIds?: ReadonlyArray<string> | null;
};
export type GenerateWithAIButtonMutation$variables = {
  input: CreateAIGenerationInput;
};
export type GenerateWithAIButtonMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly status: AIGenerationStatus;
    } | null;
  };
};
export type GenerateWithAIButtonMutation = {
  response: GenerateWithAIButtonMutation$data;
  variables: GenerateWithAIButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateWithAIButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateAIGenerationResponse",
        "kind": "LinkedField",
        "name": "createAIGeneration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AIGeneration",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateWithAIButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateAIGenerationResponse",
        "kind": "LinkedField",
        "name": "createAIGeneration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AIGeneration",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e2ffb5c8a29bd70d15e4e42b7b27744",
    "id": null,
    "metadata": {},
    "name": "GenerateWithAIButtonMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateWithAIButtonMutation(\n  $input: CreateAIGenerationInput!\n) {\n  response: createAIGeneration(input: $input) {\n    node {\n      status\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b669fa67a1a440ebf6fa1a40b1c17e3";

export default node;
