/**
 * @generated SignedSource<<481041c67f39f0bebe34776478b845ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AIGenerationReferenceStatus = "completed" | "failed" | "pending" | "%future added value";
export type AIGenerationStatus = "completed" | "draft" | "failed" | "pending" | "running" | "%future added value";
export type useTrackAIGenerationStatusQuery$variables = {
  curriculumId: string;
};
export type useTrackAIGenerationStatusQuery$data = {
  readonly curriculum: {
    readonly aiGeneration?: {
      readonly acceptedMessageId: string | null;
      readonly id: string;
      readonly references: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly embeddingSource: {
              readonly asset: {
                readonly name: string;
              } | null;
              readonly content: {
                readonly name: string | null;
              } | null;
            } | null;
            readonly id: string;
            readonly status: AIGenerationReferenceStatus;
            readonly summary: string | null;
            readonly uploadedAsset: {
              readonly name: string;
            } | null;
            readonly url: string | null;
          };
        }>;
      };
      readonly status: AIGenerationStatus;
    } | null;
  } | null;
};
export type useTrackAIGenerationStatusQuery = {
  response: useTrackAIGenerationStatusQuery$data;
  variables: useTrackAIGenerationStatusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "curriculumId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "curriculumId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptedMessageId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = [
  (v7/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useTrackAIGenerationStatusQuery",
    "selections": [
      {
        "alias": "curriculum",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AIGeneration",
                "kind": "LinkedField",
                "name": "aiGeneration",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AIGenerationReferenceNodeConnection",
                    "kind": "LinkedField",
                    "name": "references",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AIGenerationReferenceNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AIGenerationReference",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EmbeddingSource",
                                "kind": "LinkedField",
                                "name": "embeddingSource",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Asset",
                                    "kind": "LinkedField",
                                    "name": "asset",
                                    "plural": false,
                                    "selections": (v8/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Content",
                                    "kind": "LinkedField",
                                    "name": "content",
                                    "plural": false,
                                    "selections": (v8/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Asset",
                                "kind": "LinkedField",
                                "name": "uploadedAsset",
                                "plural": false,
                                "selections": (v8/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Curriculum",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useTrackAIGenerationStatusQuery",
    "selections": [
      {
        "alias": "curriculum",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AIGeneration",
                "kind": "LinkedField",
                "name": "aiGeneration",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AIGenerationReferenceNodeConnection",
                    "kind": "LinkedField",
                    "name": "references",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AIGenerationReferenceNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AIGenerationReference",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EmbeddingSource",
                                "kind": "LinkedField",
                                "name": "embeddingSource",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Asset",
                                    "kind": "LinkedField",
                                    "name": "asset",
                                    "plural": false,
                                    "selections": (v9/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Content",
                                    "kind": "LinkedField",
                                    "name": "content",
                                    "plural": false,
                                    "selections": (v9/*: any*/),
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Asset",
                                "kind": "LinkedField",
                                "name": "uploadedAsset",
                                "plural": false,
                                "selections": (v9/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Curriculum",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a341bdd438354424569ead403143ebdf",
    "id": null,
    "metadata": {},
    "name": "useTrackAIGenerationStatusQuery",
    "operationKind": "query",
    "text": "query useTrackAIGenerationStatusQuery(\n  $curriculumId: ID!\n) {\n  curriculum: node(id: $curriculumId) {\n    __typename\n    ... on Curriculum {\n      aiGeneration {\n        id\n        status\n        acceptedMessageId\n        references {\n          edges {\n            node {\n              id\n              status\n              url\n              summary\n              embeddingSource {\n                asset {\n                  name\n                  id\n                }\n                content {\n                  name\n                  id\n                }\n                id\n              }\n              uploadedAsset {\n                name\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c5f986bfe2c72009786841e3ce7a2cf6";

export default node;
