import { ControlChangeUserEmailModalMutation } from "@/control/components/__generated__/ControlChangeUserEmailModalMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import {
  displayGraphQLErrorToast,
  displaySuccessToast,
} from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoFormControl,
  DiscoInput,
  DiscoModal,
  DiscoText,
} from "@disco-ui"
import { useState } from "react"
import { graphql } from "relay-runtime"

interface Props {
  isOpen: boolean
  onClose?: () => void
  onSubmit?: () => void
  userId: GlobalID
}

function ControlChangeUserEmailModal({ userId, isOpen, onClose, onSubmit }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [email, setEmail] = useState<string>("")
  const [confirmEmail, setConfirmEmail] = useState<string>("")
  const [reason, setReason] = useState<string>("")

  const commitMutation = Relay.useAsyncMutation<ControlChangeUserEmailModalMutation>(
    graphql`
      mutation ControlChangeUserEmailModalMutation($input: ControlChangeUserEmailInput!) {
        controlChangeUserEmail(input: $input) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  return (
    <DiscoModal
      testid={"ControlChangeUserEmailModal.create-new"}
      modalContentLabel={"Change User Email"}
      isOpen={isOpen}
      onClose={onClose}
      width={"600px"}
      title={`Change User Email`}
      subtitle={`Change the email address for this user.`}
      buttons={
        <DiscoButton onClick={runAction} disabled={isSubmitting}>
          {"Save"}
        </DiscoButton>
      }
      body={
        <>
          <DiscoFormControl
            variant={"two-column"}
            label={<DiscoText variant={"body-sm-600"}>{"New Email"}</DiscoText>}
            marginBottom={0.5}
          >
            <DiscoInput
              fullWidth
              name={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </DiscoFormControl>
          <DiscoFormControl
            variant={"two-column"}
            label={<DiscoText variant={"body-sm-600"}>{"Confirm Email"}</DiscoText>}
            marginBottom={0.5}
          >
            <DiscoInput
              fullWidth
              name={"confirmEmail"}
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
            />
          </DiscoFormControl>
          <DiscoFormControl
            variant={"two-column"}
            label={<DiscoText variant={"body-sm-600"}>{"Reason for Change"}</DiscoText>}
            marginBottom={0.5}
          >
            <DiscoInput
              fullWidth
              name={"reason"}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </DiscoFormControl>
        </>
      }
    />
  )

  async function runAction() {
    if (isSubmitting) return
    setIsSubmitting(true)
    if (email !== confirmEmail) {
      displayGraphQLErrorToast({ message: "Emails do not match" })
      setIsSubmitting(false)
      return
    }
    const res = await commitMutation({
      input: {
        userId,
        newEmail: email,
        reason,
      },
    }).catch((err) => {
      displayGraphQLErrorToast(err)
    })
    setIsSubmitting(false)
    if (!res) return
    if (res.controlChangeUserEmail.errors) {
      displayGraphQLErrorToast(res.controlChangeUserEmail.errors[0])
    } else {
      displaySuccessToast({ message: "Email has been changed successfully" })
      if (onSubmit) onSubmit()
      if (onClose) onClose()
    }
  }
}

export default ControlChangeUserEmailModal
