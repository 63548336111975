import { ControlAppContextPreloadedQuery } from "@/control/ControlAppContextQuery"
import {
  ControlAppContextQuery,
  ControlAppContextQuery$data,
} from "@/control/__generated__/ControlAppContextQuery.graphql"
import { DefaultProviderProps } from "@/core/context/ContextProviders"
import { createContext, useContext } from "react"
import { PreloadedQuery, graphql, usePreloadedQuery } from "react-relay"

export type ControlAuthUserData<T extends boolean = false> = T extends true
  ? NonNullable<ControlAppContextQuery$data["viewer"]>
  : ControlAppContextQuery$data["viewer"] | null
export type ControlAuthUserContextValue<T extends boolean = false> = T extends true
  ? {
      controlAuthUser: ControlAuthUserData<true>
    }
  : {
      controlAuthUser: ControlAuthUserData
    }

const ControlAuthUserContext = createContext<ControlAuthUserContextValue | null>(null)

export function useControlAuthUser<T extends boolean = false>() {
  // @ts-expect-error
  const value: ControlAuthUserContextValue<T> = useContext(ControlAuthUserContext)
  if (!value)
    throw new Error("useControlAuthUser used outside of ControlAuthUserProvider context")

  // If there is not a controlAuthUser then just redirect out, don't give any indication
  // for anyone attempting to load that there is anything here
  if (!value.controlAuthUser?.isStaff) {
    window.location.href = BASE_DOMAIN_URL
  }
  return value
}

export type ControlPreloadedProviderProps = {
  queryRef: PreloadedQuery<ControlAppContextQuery>
} & DefaultProviderProps

const ControlAuthUserProvider = (props: ControlPreloadedProviderProps) => {
  const { viewer } = usePreloadedQuery<ControlAppContextQuery>(
    ControlAppContextPreloadedQuery,
    props.queryRef
  )

  return (
    <ControlAuthUserContext.Provider
      value={{
        controlAuthUser: viewer,
      }}
    >
      {props.children}
    </ControlAuthUserContext.Provider>
  )
}

export default ControlAuthUserProvider

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ControlAuthUserContextFragment on User {
    id
    timezone
    avatar
    email
    firstName
    lastName
    fullName
    isVerified
    isStaff
    isControlSuperuser
  }
`
