/**
 * @generated SignedSource<<f4ba4659c2b1bfa2eb6f6e63cc89e276>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PostCardFragment$data = {
  readonly content: {
    readonly id: string;
    readonly name: string | null;
    readonly type: ContentType;
    readonly " $fragmentSpreads": FragmentRefs<"ContentCard_ContentFragment" | "ContentFlaggingsChipFragment" | "FlagContentButtonFragment" | "useCanFlagContentFragment">;
  };
  readonly feed: {
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  };
  readonly feedId: string;
  readonly id: string;
  readonly pinnedPost: {
    readonly id: string;
    readonly ordering: number;
  } | null;
  readonly product: {
    readonly id: string;
    readonly name: string;
    readonly slug: string;
    readonly status: ProductStatus;
  } | null;
  readonly releasedAt: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DeletePostButtonFragment" | "EditPostButtonFragment" | "SharePostButtonFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "PostCardFragment";
};
export type PostCardFragment$key = {
  readonly " $data"?: PostCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostCardFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "usePermissionsFragment"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dashboardId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "feedId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feedId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releasedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Feed",
      "kind": "LinkedField",
      "name": "feed",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentCard_ContentFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FlagContentButtonFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentFlaggingsChipFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useCanFlagContentFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "dashboardId",
          "variableName": "dashboardId"
        },
        {
          "kind": "Variable",
          "name": "feedId",
          "variableName": "feedId"
        }
      ],
      "concreteType": "PinnedPost",
      "kind": "LinkedField",
      "name": "pinnedPost",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ordering",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditPostButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeletePostButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharePostButtonFragment"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();

(node as any).hash = "36171b62c4efc6867d94285d8f0308f4";

export default node;
