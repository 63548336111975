import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { TestIDProps } from "@utils/typeUtils"
import { useMemo } from "react"
import { graphql } from "react-relay"
import type { AIFormAttachmentTagsSelectedEmbeddingSourcesQuery } from "./__generated__/AIFormAttachmentTagsSelectedEmbeddingSourcesQuery.graphql"

type Props = TestIDProps & {
  assetEmbeddingSourceIds: GlobalID[]
  contentEmbeddingSourceIds: GlobalID[]
  uploadedAssets: MediaResult[]
  referenceUrl?: string | null
  onRemoveAssetEmbeddingSource: (id: GlobalID) => void
  onRemoveContentEmbeddingSource: (id: GlobalID) => void
  onRemoveUploadedAsset: (media: MediaResult) => void
  onRemoveReferenceUrl: () => void
}

function AIFormAttachmentTags(props: Props) {
  const {
    testid = "AIFormAttachmentTags",
    assetEmbeddingSourceIds,
    contentEmbeddingSourceIds,
    uploadedAssets,
    referenceUrl,
    onRemoveAssetEmbeddingSource,
    onRemoveContentEmbeddingSource,
    onRemoveUploadedAsset,
    onRemoveReferenceUrl,
  } = props

  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!

  // Fetch names for selected embedding sources
  const { data: assetData } =
    Relay.useRefetchablePaginationQuery<AIFormAttachmentTagsSelectedEmbeddingSourcesQuery>(
      useSelectedEmbeddingSources,
      {
        id: activeOrganization.id,
        first: 100,
        entity: "asset",
        search: assetEmbeddingSourceIds.join(","),
      },
      { connectionName: "node.embeddingSources" }
    )

  const { data: contentData } =
    Relay.useRefetchablePaginationQuery<AIFormAttachmentTagsSelectedEmbeddingSourcesQuery>(
      useSelectedEmbeddingSources,
      {
        id: activeOrganization.id,
        first: 100,
        entity: "content",
        search: contentEmbeddingSourceIds.join(","),
      },
      { connectionName: "node.embeddingSources" }
    )

  const assetSources = Relay.connectionToArray(assetData?.node?.embeddingSources)
  const contentSources = Relay.connectionToArray(contentData?.node?.embeddingSources)
  const selectedSourcesMap = useMemo(() => {
    const map = new Map<GlobalID, string>()

    assetSources.forEach((node) => {
      if (!node?.asset?.name) return
      map.set(node.id, node.asset.name)
    })

    contentSources.forEach((node) => {
      if (!node?.content?.name) return
      map.set(node.id, node.content.name)
    })

    return map
  }, [assetSources, contentSources])

  const totalCount =
    assetEmbeddingSourceIds.length +
    contentEmbeddingSourceIds.length +
    uploadedAssets.length +
    (referenceUrl ? 1 : 0)

  if (totalCount === 0) return null

  return (
    <div className={classes.wrapper}>
      {assetEmbeddingSourceIds.map((id) => (
        <DiscoTag
          key={id}
          testid={`${testid}.selected-asset.${id}`}
          borderRadius={10}
          className={classes.tag}
          name={selectedSourcesMap.get(id) || "Loading..."}
          onDelete={() => onRemoveAssetEmbeddingSource(id)}
        />
      ))}
      {contentEmbeddingSourceIds.map((id) => (
        <DiscoTag
          key={id}
          testid={`${testid}.selected-content.${id}`}
          borderRadius={10}
          className={classes.tag}
          name={selectedSourcesMap.get(id) || "Loading..."}
          onDelete={() => onRemoveContentEmbeddingSource(id)}
        />
      ))}
      {uploadedAssets.map((asset) => (
        <DiscoTag
          key={asset.id}
          testid={`${testid}.selected-upload.${asset.id}`}
          borderRadius={10}
          className={classes.tag}
          name={asset.name}
          onDelete={() => onRemoveUploadedAsset(asset)}
        />
      ))}
      {referenceUrl && (
        <DiscoTag
          key={"reference-url"}
          testid={`${testid}.reference-url`}
          borderRadius={10}
          className={classes.tag}
          name={referenceUrl}
          onDelete={onRemoveReferenceUrl}
        />
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
    width: "100%",
  },
  tag: {
    maxWidth: "calc(100% - 4px)",
  },
}))

const useSelectedEmbeddingSources = graphql`
  query AIFormAttachmentTagsSelectedEmbeddingSourcesQuery(
    $id: ID!
    $first: Int!
    $after: String
    $search: String
    $entity: EmbeddingSourceEntity!
  ) {
    node(id: $id) {
      ... on Organization {
        embeddingSources(first: $first, after: $after, search: $search, entity: $entity)
          @connection(key: "AIFormAttachmentTags_embeddingSources") {
          edges {
            node {
              id
              entity
              asset {
                id
                name
              }
              content {
                id
                name
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`

export default AIFormAttachmentTags
