/**
 * @generated SignedSource<<d83704c84b20b73bab02d692abcc67b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentMediaSectionFragment$data = {
  readonly coverPhoto: string | null;
  readonly coverVideo: string | null;
  readonly coverVideoAssetUsage: {
    readonly asset: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"DiscoVideoFragment">;
    };
    readonly id: string;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "ContentMediaSectionFragment";
};
export type ContentMediaSectionFragment$key = {
  readonly " $data"?: ContentMediaSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentMediaSectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentMediaSectionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverPhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverVideo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetUsage",
      "kind": "LinkedField",
      "name": "coverVideoAssetUsage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Asset",
          "kind": "LinkedField",
          "name": "asset",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DiscoVideoFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};
})();

(node as any).hash = "558feb53706045b1dc6bfd52075037ec";

export default node;
