/**
 * @generated SignedSource<<d87049a81176930077aba215fc16b5cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentCard_ContentFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BookmarkButtonContentFragment" | "ContentAttachmentsSectionFragment" | "ContentDescriptionSectionFragment" | "ContentFooterSection_ContentFragment" | "ContentHeaderSectionFragment" | "ContentMediaSectionFragment" | "ContentTitleSectionFragment">;
  readonly " $fragmentType": "ContentCard_ContentFragment";
};
export type ContentCard_ContentFragment$key = {
  readonly " $data"?: ContentCard_ContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentCard_ContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentCard_ContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentHeaderSectionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentMediaSectionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentTitleSectionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentDescriptionSectionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentAttachmentsSectionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentFooterSection_ContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookmarkButtonContentFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "a16a1fad9c49ab545c937434efc17ed7";

export default node;
