import { AICanvasDrawerMessageFragment$key } from "@/content/ai/drawer/__generated__/AICanvasDrawerMessageFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoIcon, DiscoIconButton, DiscoLink, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { Collapse } from "@material-ui/core"
import classNames from "classnames"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

type AICanvasDrawerMessageProps = {
  messageKey: AICanvasDrawerMessageFragment$key
  isSelected?: boolean
  onSelect: (messageId: string) => void
  isFirstOutput?: boolean
  draftName?: string
}

function AICanvasDrawerMessage({
  messageKey,
  isSelected,
  onSelect,
  isFirstOutput = false,
  draftName,
}: AICanvasDrawerMessageProps) {
  const classes = useStyles()
  const [isSourcesExpanded, setIsSourcesExpanded] = useState(false)

  const data = useFragment(
    graphql`
      fragment AICanvasDrawerMessageFragment on AIGenerationMessage {
        id
        kind
        output {
          responseMessage
          searchResults {
            moduleTitle
            query
            urls
          }
          curriculumModules {
            title
            content {
              title
              type
              summary
            }
          }
        }
        input {
          prompt
          searchWeb
        }
      }
    `,
    messageKey
  )

  const getResponseMessage = () => {
    // If we have a message from the AI generation output, use it
    if (data.output?.responseMessage) {
      return data.output.responseMessage
    }

    // Otherwise, fall back to the static messages
    if (isFirstOutput) {
      return "I've created a comprehensive curriculum outline for the above prompt. Let me know if you'd like to expand on any section or adjust the content further!"
    }
    return "I've updated the draft based on your feedback. Would you like to refine it further?"
  }

  if (data.kind === "input" && data.input?.prompt) {
    return (
      <div
        className={classes.inputMessageBox}
        data-testid={`message-sidebar.message.${data.id}`}
      >
        <DiscoText variant={"body-md"}>{data.input.prompt}</DiscoText>
      </div>
    )
  }

  if (data.kind === "output") {
    return (
      <div
        className={classes.responseContainer}
        data-testid={`message-sidebar.message.${data.id}`}
      >
        <DiscoEditor
          readOnly={true}
          markdown={getResponseMessage()}
          disableGutter={true}
          showOutline={false}
          className={classes.responseText}
        />

        <DiscoContainerButton
          onClick={() => onSelect(data.id)}
          className={classNames(classes.curriculumHeader, {
            [classes.selectedOutputMessage]: isSelected,
          })}
        >
          <DiscoIcon icon={"book"} color={"black"} width={20} height={20} />
          <DiscoText variant={"body-md-700"} className={classes.headerTitle}>
            {draftName}
          </DiscoText>
        </DiscoContainerButton>

        {data.output?.searchResults && data.output.searchResults.length > 0 && (
          <div className={classes.sourcesSection}>
            <div className={classes.sourcesHeader}>
              <DiscoIconButton
                testid={`message-${data.id}.sources.expand-button`}
                onClick={() => setIsSourcesExpanded((prev) => !prev)}
                tooltip={"Fetched Web Sources"}
              >
                <DiscoIcon icon={"globe"} />
              </DiscoIconButton>
              <DiscoText variant={"body-sm"}>{"Web Sources"}</DiscoText>
            </div>
            <Collapse in={isSourcesExpanded}>
              <div className={classes.sourcesList}>
                {data.output.searchResults.map((result) =>
                  result.urls.map((url) => (
                    <DiscoLink
                      key={url}
                      to={url}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                      className={classes.sourceLink}
                    >
                      <DiscoText variant={"body-xs"} className={classes.sourceUrl}>
                        {url}
                      </DiscoText>
                    </DiscoLink>
                  ))
                )}
              </div>
            </Collapse>
          </div>
        )}
      </div>
    )
  }

  return null
}

const useStyles = makeUseStyles((theme) => ({
  inputMessageBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.groovy.neutral[200],
    borderRadius: theme.measure.borderRadius.medium,
  },
  selectedOutputMessage: {
    border: `2px solid ${theme.palette.primary.main} !important`,
  },
  responseContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  responseText: {
    color: theme.palette.text.secondary,
  },
  sourcesSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  sourcesList: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    paddingLeft: theme.spacing(4),
  },
  sourceLink: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  sourceUrl: {
    color: theme.palette.text.secondary,
  },
  sourcesHeader: {
    display: "flex",
    alignItems: "center",
  },
  curriculumHeader: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
    border: theme.palette.constants.borderSmall,
    borderRadius: theme.measure.borderRadius.big,
    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  },
  headerTitle: {
    color: theme.palette.text.primary,
  },
}))

export default AICanvasDrawerMessage
