/**
 * @generated SignedSource<<07a3c48639efca69c6bde33f35cc1bf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ControlUserDetailPageQuery$variables = {
  id: string;
};
export type ControlUserDetailPageQuery$data = {
  readonly user: {
    readonly canCreateMultipleTrials: boolean;
    readonly email: string;
    readonly fullName: string;
    readonly id: string;
    readonly organizationMemberships: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly isDiscoStaff: boolean;
          readonly organization: {
            readonly id: string;
            readonly name: string;
          };
        };
      }>;
    };
  };
};
export type ControlUserDetailPageQuery = {
  response: ControlUserDetailPageQuery$data;
  variables: ControlUserDetailPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": "user",
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ControlUser",
    "kind": "LinkedField",
    "name": "controlGetUser",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canCreateMultipleTrials",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationMembershipNodeConnection",
        "kind": "LinkedField",
        "name": "organizationMemberships",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembershipNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDiscoStaff",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ControlUserDetailPageQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ControlUserDetailPageQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "15069264b923ea3c5fca366b743a7e0e",
    "id": null,
    "metadata": {},
    "name": "ControlUserDetailPageQuery",
    "operationKind": "query",
    "text": "query ControlUserDetailPageQuery(\n  $id: ID!\n) {\n  user: controlGetUser(id: $id) {\n    id\n    fullName\n    email\n    canCreateMultipleTrials\n    organizationMemberships {\n      edges {\n        node {\n          id\n          isDiscoStaff\n          organization {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "34528c3bdfd70574c065f6f199843222";

export default node;
