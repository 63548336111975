import { BookmarkKind } from "@/bookmarks/__generated__/BookmarkButtonCreateMutation.graphql"
import { ContentCard_ContentFragment$key } from "@/content/detail/__generated__/ContentCard_ContentFragment.graphql"
import { ContentCard_ContentUsageFragment$key } from "@/content/detail/__generated__/ContentCard_ContentUsageFragment.graphql"
import ContentAttachmentsSection, {
  ContentAttachmentsSectionSkeleton,
} from "@/content/detail/sections/ContentAttachmentsSection"
import ContentDescriptionSection, {
  ContentDescriptionSectionSkeleton,
} from "@/content/detail/sections/ContentDescriptionSection"
import ContentFooterSection, {
  ContentFooterSectionSkeleton,
} from "@/content/detail/sections/ContentFooterSection"
import ContentHeaderSection, {
  ContentHeaderSectionSkeleton,
} from "@/content/detail/sections/ContentHeaderSection"
import ContentMediaSection, {
  ContentMediaSectionSkeleton,
} from "@/content/detail/sections/ContentMediaSection"
import ContentTitleSection, {
  ContentTitleSectionSkeleton,
} from "@/content/detail/sections/ContentTitleSection"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { Theme, useMediaQuery } from "@material-ui/core"
import classNames from "classnames"
import { useFragment } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"
import { CoverPosition } from "@/product/card/ProductCard"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"

export interface ContentCardProps {
  contentKey: ContentCard_ContentFragment$key
  contentUsageKey?: ContentCard_ContentUsageFragment$key
  testid: string
  onClick?: VoidFunction
  onCommentButtonClick?: VoidFunction
  detailPagePath?: string
  banner?: React.ReactNode
  overflow?: React.ReactNode
  headerContent?: React.ReactNode
  titleContent?: React.ReactNode
  headerRightContent?: React.ReactNode
  footerRightContent?: React.ReactNode
  className?: string
  hideHeader?: boolean
  hideDescription?: boolean
  hideTitle?: boolean
  hideMedia?: boolean
  hideAttachments?: boolean
  compact?: boolean
  hasMedia?: boolean
  readOnlyReactionButton?: boolean
  bookmarkKind?: BookmarkKind
  classes?: {
    overflow?: string
    footerContainer?: string
  }
  coverPosition?: CoverPosition
}

function ContentCard({
  contentKey,
  contentUsageKey,
  testid,
  onClick,
  onCommentButtonClick,
  detailPagePath,
  banner,
  overflow,
  headerContent,
  titleContent,
  headerRightContent,
  footerRightContent,
  className,
  hideHeader = false,
  hideDescription = false,
  hideMedia = false,
  hideAttachments = false,
  hasMedia = true,
  hideTitle = false,
  compact = false,
  readOnlyReactionButton,
  bookmarkKind,
  coverPosition = "top",
  classes: propsClasses,
}: ContentCardProps) {
  const history = useHistory()

  const content = useFragment<ContentCard_ContentFragment$key>(
    graphql`
      fragment ContentCard_ContentFragment on Content {
        id
        ...ContentHeaderSectionFragment
        ...ContentMediaSectionFragment
        ...ContentTitleSectionFragment
        ...ContentDescriptionSectionFragment
        ...ContentAttachmentsSectionFragment
        ...ContentFooterSection_ContentFragment
        ...BookmarkButtonContentFragment
      }
    `,
    contentKey
  )

  const contentUsage = useFragment<ContentCard_ContentUsageFragment$key>(
    graphql`
      fragment ContentCard_ContentUsageFragment on ContentUsage {
        id
        ...ContentFooterSection_ContentUsageFragment
      }
    `,
    contentUsageKey || null
  )

  const isMdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
  const classes = useStyles({ coverPosition, hasMedia })
  const onShowHoverClasses = useShowOnHoverStyles()

  if (!content && !contentUsage) return null

  if (coverPosition === "left") {
    return (
      <DiscoContainerButton
        className={classNames(classes.container, className, onShowHoverClasses.hoverable)}
        testid={testid}
        onClick={handleGoToDetailPage}
      >
        {!hideMedia && (
          <ContentMediaSection
            className={classes.media}
            contentKey={content}
            contentUsageId={contentUsage?.id}
            testid={testid}
            objectFit
          />
        )}
        <div className={classes.body}>
          {!hideTitle &&
            (titleContent || (
              <ContentTitleSection
                className={classes.title}
                contentKey={content}
                testid={testid}
                textVariant={"body-md-600"}
              />
            ))}

          {!hasMedia && (
            <ContentDescriptionSection
              className={classes.description}
              contentKey={content}
              contentUsageId={contentUsage?.id}
              testid={testid}
              disableMediaModal
            />
          )}

          <div
            className={classNames(classes.footerContainer, propsClasses?.footerContainer)}
          >
            <ContentFooterSection
              contentUsageKey={contentUsage!}
              contentKey={content}
              testid={testid}
              onCommentButtonClick={handleGoToDetailPage}
              hideText={compact || isMdScreen}
              readOnlyReactionButton={readOnlyReactionButton}
              bookmarkKind={bookmarkKind}
              customHoverClasses={onShowHoverClasses.showable}
              hideReactionsList={compact}
              hideBookmarkText
              footerRightContent={footerRightContent}
            />
          </div>
        </div>
      </DiscoContainerButton>
    )
  }

  return (
    <DiscoContainerButton
      className={classNames(classes.container, onShowHoverClasses.hoverable, className)}
      testid={testid}
      onClick={handleGoToDetailPage}
    >
      {hideHeader
        ? null
        : headerContent || (
            <ContentHeaderSection
              classes={{ overflow: propsClasses?.overflow }}
              className={classes.header}
              contentKey={content}
              testid={testid}
              overflow={
                <>
                  <>{overflow}</>
                  <>{headerRightContent}</>
                </>
              }
            />
          )}

      {banner}

      {!hideMedia && (
        <ContentMediaSection
          className={classes.media}
          contentKey={content}
          contentUsageId={contentUsage?.id}
          testid={testid}
        />
      )}

      {!hideTitle &&
        (titleContent || (
          <ContentTitleSection
            className={classes.title}
            contentKey={content}
            testid={testid}
          />
        ))}

      {!hideDescription && (
        <ContentDescriptionSection
          className={classes.description}
          contentKey={content}
          contentUsageId={contentUsage?.id}
          testid={testid}
          disableMediaModal
        />
      )}
      {!hideAttachments && (
        <ContentAttachmentsSection
          className={classes.attachments}
          contentKey={content}
          testid={testid}
        />
      )}

      <div className={classNames(classes.footerContainer, propsClasses?.footerContainer)}>
        <ContentFooterSection
          {...(contentUsage
            ? { contentUsageKey: contentUsage! }
            : { contentKey: content })}
          testid={testid}
          onCommentButtonClick={onCommentButtonClick}
          hideText={compact || isMdScreen}
          readOnlyReactionButton={readOnlyReactionButton}
          bookmarkKind={bookmarkKind}
          customHoverClasses={onShowHoverClasses.showable}
          hideReactionsList={compact}
          hideBookmarkText
          footerRightContent={footerRightContent}
        />
      </div>
    </DiscoContainerButton>
  )

  function handleGoToDetailPage() {
    if (detailPagePath) {
      history.push(detailPagePath)
    } else if (onClick) {
      onClick()
    }
  }
}

interface StyleProps {
  coverPosition: CoverPosition
  hasMedia: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ coverPosition }: StyleProps) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderDashboardCard,
    display: "flex",
    height: "100%",
    ...styleIf(coverPosition === "top", {
      flexDirection: "column",
    }),
    ...styleIf(coverPosition === "left", {
      padding: 0,
    }),
  }),
  header: ({ coverPosition }: StyleProps) => ({
    marginBottom: theme.spacing(2.5),

    ...styleIf(coverPosition === "left", {
      justifyContent: "space-between",
    }),
  }),
  media: ({ coverPosition }: StyleProps) => ({
    padding: 0,
    ...styleIf(coverPosition === "left", {
      height: "130px",
      minWidth: "260px",
    }),
    ...styleIf(coverPosition === "top", {
      marginBottom: theme.spacing(2.5),
    }),
  }),
  title: { marginBottom: theme.spacing(1) },
  description: { marginBottom: theme.spacing(2.5), flex: "1 1 auto" },
  attachments: { marginBottom: theme.spacing(2.5) },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  body: ({ coverPosition, hasMedia }: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",

    ...styleIf(!hasMedia, {
      gap: theme.spacing(1.5),
    }),

    ...styleIf(coverPosition === "left", {
      padding: theme.spacing(2),
    }),
  }),
}))

export function ContentCardSkeleton() {
  const classes = useStyles({ coverPosition: "top", hasMedia: false })
  return (
    <div className={classes.container}>
      <ContentHeaderSectionSkeleton className={classes.header} />
      <ContentMediaSectionSkeleton className={classes.media} />
      <ContentTitleSectionSkeleton className={classes.title} />
      <ContentDescriptionSectionSkeleton className={classes.description} />
      <ContentAttachmentsSectionSkeleton />
      <div className={classes.footerContainer}>
        <ContentFooterSectionSkeleton />
      </div>
    </div>
  )
}

export default Relay.withSkeleton({
  component: ContentCard,
  skeleton: ContentCardSkeleton,
})
