import { AICanvasDrawerFormState } from "@/content/ai/drawer/AICanvasDrawerContent"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSection, DiscoText } from "@disco-ui"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import CurriculumActionButtons from "./CurriculumActionButtons"
import CurriculumModuleItem from "./CurriculumModuleItem"

interface CurriculumModuleProps {
  title: string
  index: number
  content: Array<{
    title: string
    summary: string
    type: string
  }>
  form: FormStore<AICanvasDrawerFormState>
  aiGenerationId: GlobalID
  isWelcomeModule?: boolean
}

const CurriculumModule = observer(
  ({
    title,
    index,
    content,
    form,
    aiGenerationId,
    isWelcomeModule = false,
  }: CurriculumModuleProps) => {
    const classes = useStyles()
    const [isHovered, setIsHovered] = useState(false)

    const displayTitle = isWelcomeModule ? title : `Module ${index + 1}: ${title}`

    return (
      <DiscoSection
        className={classNames(classes.module, {
          [classes.moduleHovered]: isHovered,
        })}
        radius={"medium"}
      >
        <div
          className={classes.hoverWrapper}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className={classes.moduleTitle}>
            <DiscoText variant={"heading-md"}>{displayTitle}</DiscoText>
            {isHovered && (
              <CurriculumActionButtons
                type={"module"}
                form={form}
                aiGenerationId={aiGenerationId}
                moduleIndex={index}
                name={title}
                isWelcomeModule={isWelcomeModule}
              />
            )}
          </div>
        </div>
        {!isWelcomeModule && (
          <div className={classes.items}>
            {content.map((item, itemIndex) => (
              <CurriculumModuleItem
                key={`${item.title}`}
                item={item}
                form={form}
                aiGenerationId={aiGenerationId}
                moduleIndex={index}
                itemIndex={itemIndex}
              />
            ))}
          </div>
        )}
      </DiscoSection>
    )
  }
)

const useStyles = makeUseStyles((theme) => ({
  module: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    margin: theme.spacing(0, -2),
    borderRadius: theme.measure.borderRadius.big,
    transition: "background-color 0.2s ease",
  },
  moduleHovered: {
    backgroundColor: theme.palette.primary.light,
  },
  hoverWrapper: {
    position: "relative",
    margin: theme.spacing(0, -8, 0, 0),
    padding: theme.spacing(0, 8, 0, 0),
  },
  moduleTitle: {
    position: "relative",
    color: theme.palette.text.primary,
    transition: "color 0.2s ease",
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.big,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      color: theme.palette.primary.main,
      "& + $items $typeChip .MuiChip-root": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  items: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.big,
    transition: "background-color 0.2s ease",
  },
}))

export default CurriculumModule
