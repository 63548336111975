/**
 * @generated SignedSource<<88a0d994d54ee7b1173ac88b62751b26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ControlUserEmailActivityQuery$variables = {
  id: string;
};
export type ControlUserEmailActivityQuery$data = {
  readonly emailActivity: {
    readonly messages: ReadonlyArray<{
      readonly fromEmail: string;
      readonly id: string;
      readonly lastEventTime: string;
      readonly opensCount: number;
      readonly status: string;
      readonly subject: string;
    }>;
  };
};
export type ControlUserEmailActivityQuery = {
  response: ControlUserEmailActivityQuery$data;
  variables: ControlUserEmailActivityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": "emailActivity",
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "EmailActivityResponse",
    "kind": "LinkedField",
    "name": "controlGetUserEmailActivity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailActivityMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subject",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fromEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastEventTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "opensCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ControlUserEmailActivityQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ControlUserEmailActivityQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b80580b6163bd2b95870e18552a95d67",
    "id": null,
    "metadata": {},
    "name": "ControlUserEmailActivityQuery",
    "operationKind": "query",
    "text": "query ControlUserEmailActivityQuery(\n  $id: ID!\n) {\n  emailActivity: controlGetUserEmailActivity(id: $id) {\n    messages {\n      id\n      subject\n      fromEmail\n      lastEventTime\n      status\n      opensCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0c3325c2abc6c31c30d708a18efe292";

export default node;
