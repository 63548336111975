import { AICanvasDrawerFormState } from "@/content/ai/drawer/AICanvasDrawerContent"
import FormStore from "@/core/form/store/FormStore"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import ConnectionHandlerPlus from "relay-connection-handler-plus"
import { RecordSourceProxy, commitLocalUpdate } from "relay-runtime"
import { v4 as uuidv4 } from "uuid"

interface UseSubmitAIMessageProps {
  form: FormStore<AICanvasDrawerFormState>
  aiGenerationId: GlobalID
}

export function useSubmitAICanvasMessage({
  form,
  aiGenerationId,
}: UseSubmitAIMessageProps) {
  const getMessagesConnection = (store: RecordSourceProxy) => {
    const aiGenerationRecord = store.get(aiGenerationId)
    if (!aiGenerationRecord) {
      console.warn("No AI generation record found.s")
      return undefined
    }

    const [messagesConnection] = ConnectionHandlerPlus.getConnections(
      aiGenerationRecord,
      "AICanvasDrawerMessageSidebarFragment_messages"
    )
    if (!messagesConnection) {
      console.warn("No messages connection found for AI generation")
      return undefined
    }
    return messagesConnection
  }

  const submitMessage = async (
    prompt: string,
    moduleIndex?: number | null,
    contentIndex?: number | null
  ) => {
    const { didSave } = await form.submit({
      id: aiGenerationId,
      prompt,
      includeWelcomeModule: form.state.includeWelcomeModule,
      curriculumModules: form.state.curriculumModules,
      searchWeb: form.state.searchWeb,
      promptModuleIndex: moduleIndex,
      promptContentIndex: contentIndex,
      ...(form.state.referenceUrl && { referenceUrl: form.state.referenceUrl }),
      uploadedAssetIds: form.state.uploadedAssetIds,
      referenceEmbeddingSourceIds: [
        ...(form.state.contentEmbeddingSourceIds || []),
        ...(form.state.assetEmbeddingSourceIds || []),
        ...(form.state.trainingDataEmbeddingSourceIds || []),
      ],
    })
    if (!didSave) return false

    form.state.prompt = ""
    form.state.referenceUrl = ""
    form.state.uploadedAssetIds?.clear()
    form.state.uploadedAssets?.clear()
    form.state.contentEmbeddingSourceIds?.clear()
    form.state.assetEmbeddingSourceIds?.clear()
    form.state.trainingDataEmbeddingSourceIds?.clear()
    form.state.referenceEmbeddingSourceIds?.clear()

    commitLocalUpdate(RelayEnvironment, (store) => {
      const messagesConnection = getMessagesConnection(store)
      if (!messagesConnection) return

      const tmpInputMessage = Relay.fabricateNode(store, "AIGenerationMessage", {
        id: `tmp_input_${uuidv4()}`,
        kind: "input",
        input: { prompt, searchWeb: form.state.searchWeb },
      })
      Relay.insertNodeIntoPaginatedConnection(store, messagesConnection, tmpInputMessage)
    })

    return true
  }

  return { submitMessage }
}
