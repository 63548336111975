/**
 * @generated SignedSource<<fa9fc69d6feef538caf6436bc1b74729>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InlineContentAttachmentsSectionFragment$data = {
  readonly attachments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly downloadUrl: string | null;
        readonly id: string;
        readonly mediaUrl: string | null;
        readonly name: string;
        readonly type: string | null;
        readonly videoUrl: string | null;
      };
    }>;
  };
  readonly " $fragmentType": "InlineContentAttachmentsSectionFragment";
};
export type InlineContentAttachmentsSectionFragment$key = {
  readonly " $data"?: InlineContentAttachmentsSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentAttachmentsSectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InlineContentAttachmentsSectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentAttachmentNodeConnection",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentAttachmentNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentAttachment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "downloadUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "videoUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mediaUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "3f224f6828a23012effe52c123775101";

export default node;
