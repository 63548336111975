import useTrackAIGenerationStatus from "@/content/ai/hooks/useTrackAIGenerationStatus"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import { DiscoButtonProps } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { graphql, useMutation } from "react-relay"
import { DeleteAIGenerationButtonMutation } from "./__generated__/DeleteAIGenerationButtonMutation.graphql"

interface DeleteAIGenerationButtonProps extends DiscoButtonProps, TestIDProps {
  children: OverridableDiscoButtonChildren
  onDelete?: (deletedAIGenerationId: string) => void
  aiGenerationId: string
  testid: string
  curriculumId: string
}

function DeleteAIGenerationButton({
  children,
  onDelete,
  aiGenerationId,
  testid,
  curriculumId,
  ...props
}: DeleteAIGenerationButtonProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { refetchStatus } = useTrackAIGenerationStatus({
    entityId: curriculumId,
  })
  const [commitMutation, isSubmitting] =
    useMutation<DeleteAIGenerationButtonMutation>(graphql`
      mutation DeleteAIGenerationButtonMutation($id: ID!) {
        response: deleteAIGeneration(id: $id) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `)

  return (
    <>
      <OverridableDiscoButton onClick={() => setIsOpen(true)} testid={testid} {...props}>
        {children}
      </OverridableDiscoButton>
      <DiscoWarningModal
        testid={`${testid}.delete-warning-modal`}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        confirmationButtonProps={{
          onClick: handleDeleteAIGeneration,
          shouldDisplaySpinner: isSubmitting,
          children: "Yes, discard it",
        }}
        modalContentLabel={"Discard Curriculum Outline"}
        title={"Discard this Outline?"}
        description={"Are you sure you want to discard this curriculum outline?"}
      />
    </>
  )

  function handleDeleteAIGeneration() {
    commitMutation({
      variables: {
        id: aiGenerationId,
      },
      onCompleted(response) {
        setIsOpen(false)

        if (response.response?.errors?.length) {
          const errorMessage = response.response.errors[0].message
          displayErrorToast(errorMessage)
        } else if (response.response?.node) {
          displayToast({ message: "Curriculum Generation Canceled" })
          onDelete?.(aiGenerationId)
        } else {
          displayErrorToast("Failed to discard curriculum outline")
        }
        refetchStatus()
      },
    })
  }
}

export default DeleteAIGenerationButton
