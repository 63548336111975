/**
 * @generated SignedSource<<0845b3e3c144bbc29467b46bbdb16c08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InlineContentCoverVideoFormFragment$data = {
  readonly coverVideoAsset: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "InlineContentCoverVideoFormFragment";
};
export type InlineContentCoverVideoFormFragment$key = {
  readonly " $data"?: InlineContentCoverVideoFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentCoverVideoFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InlineContentCoverVideoFormFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Asset",
      "kind": "LinkedField",
      "name": "coverVideoAsset",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "f287bd76ec55bd7912386279946bc23f";

export default node;
