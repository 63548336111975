import { AICanvasDrawerFormState } from "@/content/ai/drawer/AICanvasDrawerContent"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoChip, DiscoText } from "@disco-ui"
import { useState } from "react"
import CurriculumActionButtons from "./CurriculumActionButtons"

const TYPE_LABELS: Record<string, string> = {
  text: "Lesson",
  assignment: "Assignment",
  quiz: "Quiz",
}

interface CurriculumModuleItemProps {
  item: {
    title: string
    summary: string
    type: string
  }
  form: FormStore<AICanvasDrawerFormState>
  aiGenerationId: GlobalID
  moduleIndex: number
  itemIndex: number
}

const CurriculumModuleItem = ({
  item,
  form,
  aiGenerationId,
  moduleIndex,
  itemIndex,
}: CurriculumModuleItemProps) => {
  const classes = useStyles()
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className={classes.item}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={classes.hoverWrapper}>
        <div className={classes.itemTitleRow}>
          <div className={classes.itemContent}>
            <div className={classes.titleChipContainer}>
              <DiscoText component={"span"} variant={"body-md-700"}>
                {item.title}
              </DiscoText>
              <DiscoChip
                className={classes.typeChip}
                label={TYPE_LABELS[item.type] ?? item.type}
                color={"blue"}
                textVariant={"body-xs-500"}
              />
            </div>
            <DiscoText variant={"body-md"} className={classes.itemSummary}>
              {item.summary}
            </DiscoText>
          </div>

          {isHovered && (
            <CurriculumActionButtons
              type={"item"}
              form={form}
              aiGenerationId={aiGenerationId}
              moduleIndex={moduleIndex}
              itemIndex={itemIndex}
              name={item.title}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    position: "relative",
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, -2),
    borderRadius: theme.measure.borderRadius.big,
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      "& $typeChip .MuiChip-root": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  hoverWrapper: {
    position: "relative",
    margin: theme.spacing(0, -8, 0, 0),
    padding: theme.spacing(0, 8, 0, 0),
  },
  itemTitleRow: {
    display: "flex",
    alignItems: "flex-start",
    position: "relative",
  },
  titleChipContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  itemSummary: {
    color: theme.palette.text.primary,
    marginTop: theme.spacing(0.5),
  },
  itemContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    flex: 1,
    position: "relative",
    zIndex: 0,
  },
  typeChip: {
    flexShrink: 0,
  },
}))

export default CurriculumModuleItem
