/**
 * @generated SignedSource<<0b70ff5cc9b2cbdd631ba35912a1c8db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentThumbnailWithDetails_ContentFragment$data = {
  readonly creationDatetime: string;
  readonly id: string;
  readonly label: string;
  readonly name: string | null;
  readonly type: ContentType;
  readonly " $fragmentSpreads": FragmentRefs<"ContentThumbnail_ContentFragment">;
  readonly " $fragmentType": "ContentThumbnailWithDetails_ContentFragment";
};
export type ContentThumbnailWithDetails_ContentFragment$key = {
  readonly " $data"?: ContentThumbnailWithDetails_ContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentThumbnailWithDetails_ContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentThumbnailWithDetails_ContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDatetime",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentThumbnail_ContentFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "0cba5ecd78f9d1d2f79b2e79f22812d1";

export default node;
