/**
 * @generated SignedSource<<7f38d86187afad35a0503c0ac9cfc195>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ControlUserRemoveModalMutation$variables = {
  id: string;
};
export type ControlUserRemoveModalMutation$data = {
  readonly controlRemoveUser: {
    readonly data: boolean | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type ControlUserRemoveModalMutation = {
  response: ControlUserRemoveModalMutation$data;
  variables: ControlUserRemoveModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ControlRemoveUserResponse",
    "kind": "LinkedField",
    "name": "controlRemoveUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ControlUserRemoveModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ControlUserRemoveModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "01a343a4160394fc046863d56154e3ad",
    "id": null,
    "metadata": {},
    "name": "ControlUserRemoveModalMutation",
    "operationKind": "mutation",
    "text": "mutation ControlUserRemoveModalMutation(\n  $id: ID!\n) {\n  controlRemoveUser(id: $id) {\n    data\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ab429ce307e1ba1057b46b983559362";

export default node;
