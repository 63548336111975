/**
 * @generated SignedSource<<51bb3d47f87dfdaf91ab1d599e939f0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EmbeddingSourceEntity = "asset" | "content" | "training_data" | "%future added value";
export type AIFormAttachmentTagsSelectedEmbeddingSourcesQuery$variables = {
  after?: string | null;
  entity: EmbeddingSourceEntity;
  first: number;
  id: string;
  search?: string | null;
};
export type AIFormAttachmentTagsSelectedEmbeddingSourcesQuery$data = {
  readonly node: {
    readonly embeddingSources?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly asset: {
            readonly id: string;
            readonly name: string;
          } | null;
          readonly content: {
            readonly id: string;
            readonly name: string | null;
          } | null;
          readonly entity: EmbeddingSourceEntity;
          readonly id: string;
        };
      }>;
      readonly pageInfo: {
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
    };
  } | null;
};
export type AIFormAttachmentTagsSelectedEmbeddingSourcesQuery = {
  response: AIFormAttachmentTagsSelectedEmbeddingSourcesQuery$data;
  variables: AIFormAttachmentTagsSelectedEmbeddingSourcesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entity"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "kind": "Variable",
  "name": "entity",
  "variableName": "entity"
},
v7 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EmbeddingSourceNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmbeddingSource",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Asset",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Content",
            "kind": "LinkedField",
            "name": "content",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfoObjectType",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasPreviousPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v12 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AIFormAttachmentTagsSelectedEmbeddingSourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "embeddingSources",
                "args": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "concreteType": "EmbeddingSourceNodeConnection",
                "kind": "LinkedField",
                "name": "__AIFormAttachmentTags_embeddingSources_connection",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AIFormAttachmentTagsSelectedEmbeddingSourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "EmbeddingSourceNodeConnection",
                "kind": "LinkedField",
                "name": "embeddingSources",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": [
                  "search",
                  "entity"
                ],
                "handle": "connection",
                "key": "AIFormAttachmentTags_embeddingSources",
                "kind": "LinkedHandle",
                "name": "embeddingSources"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec425da3ea123709a621c37fd71890ef",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "node",
            "embeddingSources"
          ]
        }
      ]
    },
    "name": "AIFormAttachmentTagsSelectedEmbeddingSourcesQuery",
    "operationKind": "query",
    "text": "query AIFormAttachmentTagsSelectedEmbeddingSourcesQuery(\n  $id: ID!\n  $first: Int!\n  $after: String\n  $search: String\n  $entity: EmbeddingSourceEntity!\n) {\n  node(id: $id) {\n    __typename\n    ... on Organization {\n      embeddingSources(first: $first, after: $after, search: $search, entity: $entity) {\n        edges {\n          node {\n            id\n            entity\n            asset {\n              id\n              name\n            }\n            content {\n              id\n              name\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          hasNextPage\n          hasPreviousPage\n          startCursor\n          endCursor\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c16182ae223bb8ed32d55ad106b999f8";

export default node;
