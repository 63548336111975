/**
 * @generated SignedSource<<d2ee991e2d01daad8094cbc1815637b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ControlAuthUserContextFragment$data = {
  readonly avatar: string;
  readonly email: string;
  readonly firstName: string | null;
  readonly fullName: string;
  readonly id: string;
  readonly isControlSuperuser: boolean;
  readonly isStaff: boolean;
  readonly isVerified: boolean;
  readonly lastName: string | null;
  readonly timezone: string;
  readonly " $fragmentType": "ControlAuthUserContextFragment";
};
export type ControlAuthUserContextFragment$key = {
  readonly " $data"?: ControlAuthUserContextFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ControlAuthUserContextFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ControlAuthUserContextFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isStaff",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isControlSuperuser",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "d8877c42e0bcd1044b24f00f0d7e86d3";

export default node;
