import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText, DiscoTextButton } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { getTimeDifferenceAsText } from "@utils/time/timeUtils"
import { utcToZonedTime } from "date-fns-tz"
import { graphql, useFragment } from "react-relay"
import { PinnedPostCardFragment$key } from "./__generated__/PinnedPostCardFragment.graphql"

interface Props {
  postKey: PinnedPostCardFragment$key
  index: number
}

export default function PinnedPostCard({ postKey, index }: Props) {
  const testid = `PinnedPostCard-${index}`
  const timeZone = useUserTimezone()
  const drawer = useGlobalDrawer("post")
  const profileDrawer = useGlobalDrawer("profileSettings")
  const post = useFragment<PinnedPostCardFragment$key>(
    graphql`
      fragment PinnedPostCardFragment on Post {
        id
        releasedAt
        content {
          id
          name
        }
        createdBy {
          id
          fullName
          ...ProfileAvatarFragment
        }
        releaseDatetime
        creationDatetime
      }
    `,
    postKey
  )

  const classes = useStyles()

  return (
    <DiscoContainerButton
      testid={testid}
      onClick={openPostDrawer}
      className={classes.card}
    >
      <DiscoText
        testid={`${testid}.PostCard.TitleSection.title`}
        truncateText={1}
        variant={"body-sm-600"}
      >
        {post.content.name}
      </DiscoText>
      <div className={classes.creator}>
        <ProfileAvatar userKey={post.createdBy} size={20} />
        <DiscoTextButton textVariant={"body-xs"} onClick={openMemberDrawer}>
          {post.createdBy?.fullName}
        </DiscoTextButton>

        {getTimestamp()}
      </div>
    </DiscoContainerButton>
  )

  function openPostDrawer() {
    drawer.open({ postId: post.id })
  }

  function openMemberDrawer(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    e.preventDefault()
    profileDrawer.open({
      drawerProfileId: post.createdBy?.id,
      profileSettingsTab: "profile",
    })
  }

  function getTimestamp() {
    const timeToNow = getTimeDifferenceAsText(
      utcToZonedTime(new Date(post?.releasedAt || post.creationDatetime), timeZone)
    )

    return (
      <DiscoText variant={"body-xs"} color={"groovy.neutral.500"}>
        {timeToNow}
      </DiscoText>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  creator: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  card: {
    padding: theme.spacing(1),
  },
}))
