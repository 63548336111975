export const EXAMPLE_PROMPTS = [
  "Generate a curriculum for a beginner-level 'Data Science Fundamentals' course covering data manipulation, statistical analysis, machine learning basics, and visualization. Include 3 lessons per module with assignments and quizzes.",
  "Generate a curriculum for an intermediate-level 'Business Communication' course focused on email writing, presentations, and interpersonal skills. Target early-career professionals with practical exercises and case studies.",
  "Generate a curriculum for an advanced-level 'Product Management Essentials' course covering product lifecycle from research to launch. Include learning objectives, case studies and assignments for aspiring product managers.",
]

export const EXAMPLE_FOLLOWUP_PROMPTS = [
  "Add an assignment to module 3...",
  "Create a new module on...",
  "Combine modules 1 and 2...",
  "Add a quiz to module 2...",
  "Add key takeaways to each lesson...",
  "Add a final project...",
]
