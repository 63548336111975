import { useDrawerContext } from "@/core/context/DrawerContext"
import ReduceIcon from "@/core/ui/iconsax/linear/custom-collapse.svg"
import ExpandIcon from "@/core/ui/iconsax/linear/custom-expand.svg"
import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIconButton, DiscoText } from "@disco-ui"
import React from "react"

interface Props {
  title: string
  onClose: () => void
  actions?: React.ReactNode
  testid?: string
}

function AICanvasDrawerHeader({
  title,
  onClose,
  actions,
  testid = "ai-canvas-header",
}: Props) {
  const { fullScreen, setFullScreen } = useDrawerContext()
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        <DiscoText variant={"heading-sm"} className={classes.headerTitle}>
          {title}
        </DiscoText>
      </div>
      <div className={classes.headerActions}>
        {actions}
        {setFullScreen && (
          <DiscoIconButton
            className={classes.actionButton}
            onClick={() => setFullScreen(!fullScreen)}
            testid={`${testid}.expand-button`}
          >
            {fullScreen ? <ReduceIcon /> : <ExpandIcon />}
          </DiscoIconButton>
        )}
        <DiscoIconButton
          onClick={onClose}
          className={classes.actionButton}
          testid={`${testid}.close-button`}
        >
          <CloseIcon />
        </DiscoIconButton>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  headerTitle: {
    marginLeft: theme.spacing(1),
  },
  headerActions: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  actionButton: {
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.medium,
  },
}))

export default AICanvasDrawerHeader
